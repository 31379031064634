.cc--big-btn {
  border-radius: 0;
  margin: 0;
  padding: 15px 0;
  width: 100%;
  font-size: 140%;
  background-color: transparent;
  color: #0C2C48;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  borderRight: "1px solid rgb(229, 229, 228)";
  borderLeft: "1px solid rgb(229, 229, 228)";
  border-bottom: 1px solid rgb(229, 229, 228);
  border-top: 1px solid rgb(229, 229, 228);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  margin-top: 10px;

  &.disabled {
    border-right: none;
    border-left: none;
  }

  &.active {
    background-color: white;
    border-right: none;
    border-left: none;
    border-bottom: none;

    &:hover {
      background-color: white;
    }
  }

  &:hover {
    background-color: #EBF1F2;
  }
}

.cc--homepage-get-started-btn {
  background-color: rgb(244, 124, 40);
  &:hover {
    background-color: #B44100;
  }
}