@import "~@capcenter/shared/src/constants/colors.scss";

.modal-inset .modal {
	position: absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:1050;
	overflow: hidden;
}

.modal-inset .modal-backdrop {
	position: absolute;
	opacity: .9;
	background-color: white;
}
.modal-inset .modal-dialog {
	height: 100%;
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 1s linear !important;
	transform: inherit !important;
	margin: 0;
}
.modal-inset .modal-content {
	background-color: transparent;
	border-width: 0;
	color: $black-500;
	margin-top: -110;
	box-shadow: none;
}
.fade {
	opacity: 0;
}
.fade.show {
	opacity: .9;
}
.modal.show {
	display: flex !important;
	align-items: flex-start;
	justify-content: center;
}
/*
body.modal-open{
	overflow: ( (this.props.inset && this.props.visible) ? "visible": "hidden")
}
*/