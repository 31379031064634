/**
 *  Light shadows
 */
.cc-shadow-xthin {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.cc-shadow--thin {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
 
.cc-shadow--light {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1),
              0 1px 2px 0 rgba(0, 0, 0, .06);
}
 
.cc-shadow--light-2 {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
} 
 
.cc-shadow--light-3 {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1),
              0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
 
.cc-shadow--light-4 {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1),
              0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
 
.cc-shadow--light-5 {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
}
 
 
 /**
  *  Inner shadow
  */
.cc-shadow--inset {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}