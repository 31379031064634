@import "../../constants/colors";

.employee--icon {
	position: relative;
	width: 100% ;
	height: 0;
	padding-bottom: 100%;
	max-width: 400px;
	max-height: 400px;
	
	img {
		position: absolute;
		top:0;
		left:0;
		transition: opacity .5s ease;
		opacity: 0;
	}
}

.employee--icon-loaded {
	img {
		opacity: 1;
	}
}

.employee--icon-bg {
	position: absolute;
	height: 0;
	padding-bottom: 100%;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:0;
	background-color: lighten($gray-600, 60%);
}