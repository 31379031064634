.SVGAnimation {
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  pointer-events: none;
  position: relative;
  overflow: hidden;
}

.SVGAnimation img{
	position: absolute;
}