.SVGCBackground, .SVGCChat, .SVGCLock, .SVGCCheck, .SVGCCheckDepth{
	width: 100%;
	height: 100%;	
}

.SVGCChat{
  animation: chat 10s linear infinite;
}

.SVGCLock {
  animation: shake 3.28s cubic-bezier(.36,.07,.19,.97) infinite;
}

@keyframes shake {
	0%{
		transform: translate3d(0, 0, 0);
	}
  2.5%{
    transform: translate3d(-1px, 0, 0);
  }
  5%{
    transform: translate3d(2px, 0, 0);
  }
  7.5%{
    transform: translate3d(-4px, 0, 0);
  }
  10% {
    transform: translate3d(4px, 0, 0);
  }
  12.5% {
    transform: translate3d(-4px, 0, 0);
  }
	15% {
    transform: translate3d(4px, 0, 0);
  }
  17.5% {
    transform: translate3d(-4px, 0, 0);
  }
  20% {
    transform: translate3d(2px, 0, 0);
  }
  22.5% {
    transform: translate3d(-1px, 0, 0);
  }
  25%{
		transform: translate3d(0, 0, 0);
	}
	100%{
		transform: translate3d(0, 0, 0);
	}
}

.SVGCPen{
	width: 24%;
	bottom: 7.7%;
	right: 16.3%;
  animation: pen 20s ease infinite;
  transform-origin: bottom left;
}

@keyframes pen {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.SVGCMan1Arm{
	width: 6.1%;
	bottom: 28%;
	left: .1%;
  transform: rotate(-1deg);
	animation: man1arm 1.5s ease-out infinite;
  transform-origin: top right;
}

@keyframes man1arm {
  0% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(-1deg);
  }
}

.SVGCMan2Arm{
	width: 4.7%;
	bottom: 30.6%;
	left: 22%;
  transform: rotate(1deg);
	animation: man2arm 1.5s ease-out infinite;
  transform-origin: top left;
}

@keyframes man2arm {
  0% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(1deg);
  }
}

.SVGCMan1ArmShaking{
	width: 5%;
	bottom: 31.2%;
	left: 10.6%;
  transform: rotate(-20deg);
	animation: man1armshaking 1.5s ease-out infinite;
  transform-origin: bottom left;
}

@keyframes man1armshaking {
  0% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-20deg);
  }
}

.SVGCMan2ArmShaking{
	width: 4.5%;
	bottom: 34%;
	left: 13%;
  transform: rotate(20deg);
	animation: man2armshaking 1.5s ease-out infinite;
  transform-origin: top right;
}

@keyframes man2armshaking {
  0% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(20deg);
  }
}

.SVGCCheck{
	animation: checkbtn 4s ease-in infinite;
}

@keyframes checkbtn {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
	  transform: translate(2.35%, -1.31%);
  }
  100% {
    transform: translateY(0%, 0%);
  }
}
