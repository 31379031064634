@media only screen and (min-width: 992px) {
  .landing-page {
    max-width: 1140px;
  }
  .breadcrumb {
    max-width: 1140px;
  }
}

@media only screen and (min-width: 1200px) {
  .landing-page {
    max-width: 1140px;
  }
  .breadcrumb {
    max-width: 1140px;
  }
}
