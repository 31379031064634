@import '~@capcenter/shared/src/constants/colors';
@import '~@capcenter/shared/src/constants/weights';
@import '~@capcenter/shared/src/styling/Breakpoints';

html {
  font-size: 80%;
  background-color: #EBF1F2; /*(window.top!==window.self ? Colors.transparent : Colors.black500), */
}

.nav--collapse,
.footer {
  font-size: 100%;
}

html,
body,
#root {
  height: 100%;
}

body,
input {
  color: $black-500;
  font-family: 'omnes-pro', Arial, sans-serif;
  font-size: 1rem;
  font-weight: $light;
}

.bold {
  color: '#213035';
  font-family: 'omnes-pro', Arial, sans-serif;
  font-size: 1rem;
  font-weight: $bold;
}

.form-control {
  font-size: 16px; /* no input zoom on mobile */
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $gray-350;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $light;
}

h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1rem;
}

strong {
  font-weight: $bold;
}

a {
  color: $orange-500;
}

a:hover {
  color: $orange-600;
}

hr {
  border-color: $gray-400;
}

.overflow-scrolling {
  -webkit-overflow-scrolling: touch;
}

.btn:not(.btn-sm, .btn-lg) {
  font-size: inherit;
}

@media (min-width: $medium) {
  html {
    font-size: 100%;
  }
}

@media print {
  .table td,
  .table th,
  .table tr {
    background-color: transparent;
  }
}

svg {
  vertical-align: top;
}
