.SVGZBackground, .SVGZCheck, .SVGZChat, .SVGZWoman, .SVGZCheckMask{
	width: 100%;
	height: 100%;	
}

.SVGZManShadow{
	width: 8%;
	bottom: 6.75%;
	left: 3%;
	transform: scale(.9);
  transform-origin: right;
  animation: zmanshadow 8s ease infinite;
}

@keyframes zmanshadow {
  0% {
		transform: scale(.9);
  }
  30% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  73% {
    transform: scale(1.3);
  }
  86% {
		transform: scale(.9);
  }
  100% {
		transform: scale(.9);
  }
}

.SVGZMan{
	width: 11.5%;
	top: 46.4%;
	left: 9%;
	transform: rotate(2deg);
  transform-origin: bottom right;
  animation: zman 8s ease infinite;
}

@keyframes zman {
  0% {
		transform: rotate(2deg)
  }
  30% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-3deg);
  }
  73% {
    transform: rotate(-4deg);
  }
  86% {
		transform: rotate(2deg);
  }
  100% {
		transform: rotate(2deg);
  }
}

.SVGZManLeg{
	width: 7%;
	top: 77.3%;
	left: 4.5%;
  transform: translate(10%, -2%) rotate(3deg);
  transform-origin: top right;
  animation: zmanleg 8s ease infinite;
}

@keyframes zmanleg {
  0% {
    transform: translate(10%, -2%) rotate(3deg);
  }
  30% {
    transform: translate(0%, 0%) rotate(0deg);
  }
  60% {
		transform: translate(-14%, 4%) rotate(-12deg);
  }
  73% {
    transform: translate(-20%, 6%) rotate(-18deg);
  }
  86% {
		transform: translate(10%, -2%) rotate(3deg);
  }
  100% {
    transform: translate(10%, -2%) rotate(3deg);
  }
}

.SVGZManFoot{
	width: 4%;
	top: 87.3%;
	left: 3.3%;
  transform: translate(0%, -10%) rotate(6deg);
  transform-origin: top right;
  animation: zmanfoot 8s ease infinite;
}

@keyframes zmanfoot {
  0% {
    transform: translate(0%, -10%) rotate(6deg);
  }
  30% {
    transform: translate(0%, 0%) rotate(0deg);
  }
  60% {
		transform: translate(10%, 15%) rotate(-30deg);
  }
  73% {
    transform: translate(12%, 22%) rotate(-60deg);
  }
  86% {
		transform: translate(0%, -10%) rotate(6deg);
  }
  100% {
    transform: translate(0%, -10%) rotate(6deg);
  }
}

.SVGZPencil{
	width: 33%;
	top: 37%;
	left: 0%;
  transform: translate(10%, -4.25%) rotate(-2deg);
  animation: pencil 8s ease infinite;
  transform-origin: bottom right;
}

@keyframes pencil {
  0% {
    transform: translate(10%, -4.25%) rotate(-2deg);
  }
  30% {
    transform: translate(3.5%, 5%) rotate(4deg);
  }
  60% {
		transform: translate(-2%, -4%) rotate(-2deg);
  }
  73% {
    transform: translate(-4%, -5%) rotate(-3deg);
  }
  86% {
		transform: translate(8%, -5.5%) rotate(-3deg);
  }
  100% {
    transform: translate(10%, -4.25%) rotate(-2deg);
  }
}

.SVGZManArmFore{
	width: 13.2%;
	top: 54.8%;
	left: 16.3%;
	transform: translate(20%, -15%) rotate(-6deg);
  transform-origin: bottom right;
  animation: manarmfore 8s ease infinite;
}

@keyframes manarmfore {
  0% {
		transform: translate(20%, -15%) rotate(-6deg);
  }
  30% {
    transform: translate(3%, 20%) rotate(0deg);
  }
  60% {
    transform: translate(-10%, -12%) rotate(-6deg);
  }
  73% {
    transform: translate(-15%, -14%) rotate(-7deg);
  }
  86% {
		transform: translate(15%, -18%) rotate(-7deg);
  }
  100% {
		transform: translate(20%, -15%) rotate(-6deg);
  }
}

.SVGZManArmBack{
	width: 6.4%;
	top: 46.8%;
	left: 7.5%;
	transform: translate(20%, -15%) rotate(-3deg);
  transform-origin: top left;
	animation: manarmback 8s ease infinite;
}

@keyframes manarmback {
  0% {
		transform: translate(20%, -15%) rotate(-3deg);
  }
  30% {
    transform: translate(3%, -15%) rotate(-5deg);
  }
  60% {
    transform: translate(-28%, -12%) rotate(0deg);
  }
  73% {
    transform: translate(-33%, -12%) rotate(3deg);
  }
  86% {
		transform: translate(15%, -18%) rotate(0);
  }
  100% {
		transform: translate(20%, -15%) rotate(-3deg);
  }
}

.SVGZManArm{
	width: 6.4%;
	top: 46.8%;
	left: 7.5%;
}


.SVGZCheckHide1{
	width: 4%;
	top: 68%;
	left: 32.9%;
	animation: checkhide1 8s ease infinite;
}

@keyframes checkhide1 {
  0% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
  30% {
    transform: translate(-62%, 79%);
    opacity: 1;
  }
  60% {
    transform: translate(-62%, 79%);
    opacity: 0;
  }
  73% {
  	transform: translate(0%, 0%);
  	opacity: 0;
  }
  86% {
  	transform: translate(0%, 0%);
  	opacity: 1;
  }
  100% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}

.SVGZCheckHide2{
	width: 3%;
	top: 68%;
	left: 31.5%;
	animation: checkhide2 8s ease infinite;
}

@keyframes checkhide2 {
  0% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
  30% {
	  transform: translate(0%, 0%);
	  opacity: 1;
  }
  60% {
  	transform: translate(-62%, -61%);
  	opacity: 0;
  }
  73% {
  	transform: translate(0%, 0%);
  	opacity: 0;
  }
  86% {
  	transform: translate(0%, 0%);
  	opacity: 1;
  }
  100% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}

.SVGZChat{
  animation: chat 10s linear infinite;
}

.SVGZCheck{
  animation: womancheck 10s ease infinite;
}

@keyframes womancheck {
  0% {
    transform: translate(2.65%, -1.65%);
  }
  16% {
    transform: translate(0%, 0%);
  }
  33% {
    transform: translate(2.65%, -1.65%);
  }
  50% {
    transform: translate(0%, 0%);
  }
  66% {
    transform: translate(2.65%, -1.65%);
  }
  82% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(2.65%, -1.65%);
  }
}

.SVGZWomanArmBack{
	width: 15%;
	top: 53%;
	left: 59%;
	transform: rotate(30deg) translate(10%, 10%);
  animation: womanarmback 10s ease infinite;
  transform-origin: top right;
}

@keyframes womanarmback {
  0% {
    transform: rotate(30deg) translate(10%, 10%);
  }
  16% {
    transform: rotate(0deg) translate(0%, 0%);
  }
  33% {
    transform: rotate(0deg) translate(0%, 0%);
  }
  50% {
    transform: rotate(-30deg) translate(-10%, -20%);
  }
  66% {
    transform: rotate(-30deg) translate(-10%, -20%);
  }
  82% {
    transform: rotate(30deg) translate(10%, 10%);
  }
  100% {
    transform: rotate(30deg) translate(10%, 10%);
  }
}

.SVGZWomanArmFore{
	width: 15.5%;
	top: 55.8%;
	left: 65.9%;
  animation: womanarmfore 10s ease infinite;
  transform-origin: top right;
}

@keyframes womanarmfore {
  0% {
    transform: rotate(10deg);
  }
  16% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  66% {
    transform: rotate(10deg);
  }
  82% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

.SVGZWomanHead{
	width: 5.8%;
	top: 48.3%;
	left: 71.5%;	
	transform: rotate(-20deg);
  animation: womanhead 10s ease infinite;
}

@keyframes womanhead {
  0% {
    transform: rotate(-20deg);
  }
  16% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  66% {
    transform: rotate(-20deg);
  }
  82% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-20deg);
  }
}