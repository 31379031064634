.Animation {
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  pointer-events: none;
  position: relative;
}

.Animation img{
	position: absolute;
}

.SVGLBackground, .SVGLCard, .SVGLForeground, .SVGLCloud1, .SVGLCloud2, .SVGLCartFore, .SVGLCartBack, .SVGLBag {
	width: 100%;
	height: 100%;	
}

.SVGLCard {
  animation: card 5s linear infinite;
}

@keyframes card {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
    transform: translate(0%, -3%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

.SVGLCloud1 {
	animation: bounce1 18s linear infinite;
}

.SVGLCloud2 {
	animation: bounce3 15s linear infinite;
}

.SVGLCartFore, .SVGLCartBack {
  animation: cart 10s linear infinite;
}

@keyframes cart {
  0% {
    transform: translate(0%, .25%);
  }  
  5% {
    transform: translate(0%, 0%);
  }
  45% {
    transform: translate(-3.5%, 2%);
  }
  50% {
    transform: translate(-3.5%, 1.75%);
  }
  55% {
    transform: translate(-3.5%, 2%);
  }
  95% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(0%, .25%);
  }
}

.SVGLBag {
  animation: bag 10s linear infinite;
}

@keyframes bag {
  0% {
    transform: translate(0%, 0%);
  }  
  50% {
    transform: translate(-3.5%, 2%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

.Confetti {
	width: 100%;
	height: 100%;
	position: absolute;
	animation: dropp 10s linear infinite;
}

@keyframes dropp {
  0% {
    transform: translate(0%, -100%);
    opacity: 0;
  }
  10% {
	  opacity: 0;
  }
  20% {
    opacity: 100;
  }  
  80% {
    transform: translate(0%, 0%);
    opacity: 100;
  }
  100% {
    transform: translate(0%, 0%);
    opacity: 0;
  }
}


.SVGLRedConfetti1 {
	width: 2.25%;
	bottom: 9%;
	left: 17.5%;
	animation: confetti 10s linear infinite;
}

.SVGLRedConfetti2 {
	width: 2.25%;
	bottom: 17.5%;
	left: 30.75%;
	animation: confetti 10s linear infinite;
}

.SVGLRedConfetti3 {
	width: 2.25%;
	bottom: 8%;
	left: 31%;
	animation: confetti 10s linear infinite;
}

.SVGLRedConfetti4 {
	width: 2.25%;
	bottom: 9%;
	left: 48.5%;
	animation: confetti 10s linear infinite;
}

.SVGLRedConfetti5 {
	width: 2.25%;
	bottom: 9%;
	left: 65%;
  transform: rotate(90deg);
	animation: confetti2 10s linear infinite;
}

.SVGLBlueConfetti1 {
	width: 2.25%;
	bottom: 10.5%;
	left: 24%;
  transform: rotate(90deg);
	animation: confetti2 10s linear infinite;
}

.SVGLBlueConfetti2 {
	width: 2.25%;
	bottom: 11.5%;
	left: 35%;
  transform: rotate(90deg);
	animation: confetti2 10s linear infinite;
}

.SVGLBlueConfetti3 {
	width: 2.25%;
	bottom: 6%;
	left: 40.5%;
  transform: rotate(90deg);
	animation: confetti2 10s linear infinite;
}

.SVGLBlueConfetti4 {
	width: 2.25%;
	bottom: 9%;
	left: 55.5%;
	animation: confetti 10s linear infinite;
}

.SVGLBlueConfetti5 {
	width: 2.25%;
	bottom: 9%;
	left: 78%;
  transform: rotate(90deg);
	animation: confetti2 10s linear infinite;
}

@keyframes confetti {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(90deg);
  }
  10% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(90deg);
  }
  20% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  30% {
    transform: rotate(0deg);
  }
  35% {
    transform: rotate(90deg);
  }
  40% {
    transform: rotate(0deg);
  }
  45% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(0deg);
  }
  55% {
    transform: rotate(90deg);
  }
  60% {
    transform: rotate(0deg);
  }
  65% {
    transform: rotate(90deg);
  }
  70% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(90deg);
  }
  80% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes confetti2 {
  0% {
    transform: rotate(90deg);
  }
  5% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(90deg);
  }
  15% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(90deg);
  }
  35% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(90deg);
  }
  45% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
  55% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(90deg);
  }
  65% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
