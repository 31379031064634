.BasicInfo, .Woman, .Profile, .ProfileShadow, .Cloud1, .Cloud2 {
	width: 100%;
	height: 100%;	
}

.ManArm {
	width: 10%;
	height: 10%;
	top: 55%;
	left: 14%;
  animation: point 10s ease infinite;
  transform-origin: top left;
}

@keyframes point {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.ManHead {
	width: 4.5%;
	top: 53.75%;
	left: 9.5%;
  animation: look 10s ease infinite;
}

@keyframes look {
  0% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(25deg);
  }
  60% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.Magnifier {
	width: 25%;
	top: 40%;
	left: 59%;
  animation: magnify 14s ease infinite;
  transform-origin: bottom right;
}

@keyframes magnify {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.Profile {
  animation: pulse 8s  infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1.0) translate(0%, 0%);
  }
  4% {
    transform: scale(1.05) translate(-1%, 0%);
  }
  40% {
    transform: scale(.9) translate(4%, 0%);
  }
  70% {
    transform: scale(1.0) translateY(0%, 0%);
  }
  100% {
    transform: scale(1.0) translateY(0%, 0%);
  }
}

.ProfileShadow {
  animation: pulse2 8s  infinite;
}

@keyframes pulse2 {
  0% {
    transform: scale(1.0) translate(0%, 0%);
  }
  4% {
    transform: scale(1.05) translate(0%, 0%);
  }
  40% {
    transform: scale(.9) translate(2%, 2%);
  }
  70% {
    transform: scale(1.0) translateY(0%, 0%);
  }
  100% {
    transform: scale(1.0) translateY(0%, 0%);
  }
}

.Cloud1 {
	animation: bounce1 18s infinite;
}

@keyframes bounce1 {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
    transform: translate(0%, -4%);
  }
  100% {
    transform: translateY(0%, 0%);
  }
}

.Cloud2 {
  animation: bounce2 10s infinite;
}

@keyframes bounce2 {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
    transform: translate(0%, 1%);
  }
  100% {
    transform: translateY(0%, 0%);
  }
}