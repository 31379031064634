.rc-slider-handle {
	-webkit-cursor: -webkit-grab;
	cursor: grab;
}
.rc-slider-handle:active{
	-webkit-cursor: -webkit-grabbing;
	cursor: grabbing
}
.rc-slider-handle:focus{
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(0,123,255,.25) !important
}