/**
 * Breakpoints
 */
$bp-xs : 360px;
$bp-sm : 576px;
$bp-md : 768px;
$bp-lg : 992px;
$bp-xl : 1200px;


.max-w-xs {
  max-width: $bp-xs !important;
}
.max-w-sm {
  max-width: $bp-sm !important;
}
.max-w-md {
  max-width: $bp-md !important;
}
.max-w-lg {
  max-width: $bp-lg !important;
}
.max-w-xl {
  max-width: $bp-xl !important;
}

.text-decoration-none.text-decoration-none {
  text-decoration: none;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}