@import '../../constants/colors';

.employee-use {
  text-align: center;
  padding: 15px;
  cursor: pointer;
}

.employee-use--visible {
  color: $gray-450;
}

.employee-use--hidden {
  color: $gray-350;
}
