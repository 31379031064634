.SVGPBackground, .SVGPCoin, .SVGPHouse, .SVGPCheck, .SVGPCheckMid, .SVGPCheckShadow, .SVGPCloud1, .SVGPCloud2, .SVGPCloud3, .SVGPCoinsFore, .SVGPDollar1, .SVGPDollar2, .SVGPDollar3, .SVGPDollar4, .SVGPDollarShadow{
	width: 100%;
	height: 100%;	
}

.SVGPCloud1 {
		animation: cloud1 20s ease infinite;
}

@keyframes cloud1 {
  0% {
    transform: translate(0%, 0%);
  }
  25% {
    transform: translate(0%, -4%);
  }
  75% {
    transform: translate(0%, 4%);
  }
  100% {
    transform: translateY(0%, 0%);
  }
}

.SVGPCloud2 {
		animation: cloud2 15s ease infinite;
}


@keyframes cloud2 {
  0% {
    transform: translate(0%, 0%);
  }
  25% {
    transform: translate(0%, 2%);
  }
  75% {
    transform: translate(0%, -2%);
  }
  100% {
    transform: translateY(0%, 0%);
  }
}

.SVGPCloud3{
		animation: cloud3 20s ease infinite;
    transform: translate(0%, -10%);
}


@keyframes cloud3 {
  0% {
    transform: translate(0%, -10%);
  }
  50% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translateY(0%, -10%);
  }
}

.SVGPCoin {
  animation: coin 8s ease-in-out infinite;
}

.SVGPCheck{
	animation: pcheck 4s ease-in-out infinite;
}

@keyframes pcheck {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
		transform: translate(1.7%, -1.5%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

.SVGPCheckShadow{
	animation: pchecks 4s ease-in-out infinite;
}

@keyframes pchecks {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
		transform: translate(-1.8%,1.2%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

.SVGPKeyFlag {
	width: 9.5%;
	bottom: 11.9%;
	left: 39.6%;
  animation: flag 3s ease infinite alternate;
  transform-origin: top left;
}

@keyframes flag {
	0%{
		transform: rotate(0deg) skew(0deg, 0deg) translate(0%, 0%);
	}
  25% {
    transform: rotate(-20deg) skew(3deg, 10deg) translate(-5%, -5%);
  }
  50% {
    transform: rotate(-15deg) skew(10deg, 0deg) translate(-5%, -5%);
  }
  75% {
    transform: rotate(-30deg) skew(10deg, 4deg) translate(-5%, -5%);
  }
  100% {
    transform: rotate(0deg) skew(0deg, 0deg) translate(0%, 0%);
  }
}

.SVGPKeyFlagShadow {
	width: 7%;
	bottom: 12.6%;
	left: 39.8%;
  animation: flagshadow 3s ease infinite alternate;
  transform-origin: top left;
}

@keyframes flagshadow {
	0%{
		transform: rotate(0deg) skew(0deg, 0deg);
	}
  25% {
    transform: rotate(-10deg) skew(3deg, 10deg);
  }
  50% {
    transform: rotate(-7deg) skew(10deg, 0deg);
  }
  75% {
    transform: rotate(-15deg) skew(10deg, 4deg);
  }
  100% {
    transform: rotate(0deg) skew(0deg, 0deg);
  }
}

.SVGPDollar4 {
		animation: dollar4 10s ease infinite;
}

@keyframes dollar4 {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
    transform: translate(0%, -20%);
  }
  100% {
    transform: translateY(0%, 0%);
  }
}

.SVGPDollar3 {
		animation: dollar3 10s ease infinite;
}

@keyframes dollar3 {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
    transform: translate(0%, -15%);
  }
  100% {
    transform: translateY(0%, 0%);
  }
}

.SVGPDollar2 {
		animation: dollar2 10s ease infinite;
}

@keyframes dollar2 {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
    transform: translate(0%, -10%);
  }
  100% {
    transform: translateY(0%, 0%);
  }
}

.SVGPDollar1 {
		animation: dollar1 10s ease infinite;
}

@keyframes dollar1 {
  0% {
    transform: translate(0%, 0%);
  }
  50% {
    transform: translate(0%, -5%);
  }
  100% {
    transform: translateY(0%, 0%);
  }
}

.SVGPDollarShadow {
		animation: dollarshadow 10s ease infinite;
}

@keyframes dollarshadow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.95);
  }
  100% {
    transform: scale(1);
  }
}