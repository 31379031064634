/**
 *  BRAND COLORS
 */

// ORANGE
$orange-500: #F47C28;
$orange-400: lighten($orange-500, 35%);
$orange-600: darken($orange-500, 15%);
$primary: $orange-500;

$orange-secondary: #FFF0E5;

// TEAL
// $teal: #017e84;
$teal-400: #3CC5E9;
$teal-500: #009AA8;
$teal-600: #155565;
$secondary: $teal-500;


// BROWN
$brown-500: #7D706D;
$brown-400: lighten($brown-500, 35%);
$brown-600: darken($brown-500, 15%);





/**
 *  THEME COLORS
 */

// GREEN
// $green: #50a54c;
$green-500: #50A54C;
$green-450: #6FD08C;
$green-400: lighten($green-500, 25%);
$green-600: darken($green-500, 15%);

$new-green: #2C8747;


// BLUE-GRAY
$blue-gray-100: #F8F9FA;
$blue-gray-300: #90A8C3;





/**
 *  SYSTEM COLORS
 */

// POSITIVE
$positive: #3BE38D;


// WARNING/YELLOW
// $yellow: #ffc107;
$yellow-500: #FABC3C;
$warning: $yellow-500;
$yellow-400: lighten($yellow-500, 20%);
$yellow-600: darken($yellow-500, 15%);


// NEGATIVE/RED
// $red: #AC2B28;
$negative: #EE6055;
$red-500: $negative;
// xLightRed
$red-300: lighten($red-500, 50%);

// lightRed
$red-400: lighten($red-500, 35%);

// darkRed
$red-600: darken($red-500, 15%);

$brightRed: #d0021b;


// ACCENT
$accent: #9BBDF9;

$new-accent: #3BB1BC;





/**
 *  WHITES, GRAYS, BLACKS
 */

// TRANSPARENT
$transparent: rgba(0, 0, 0, 0);

// WHITE
$white-100: #FFFFFF;
$white-200: #FAFAFA;


// BLACK
$black-500: #323333;
$black-900: #000000;


// GRAYS
// $gray: #403f3e;
// $gray: #595959;
// $gray-600: #595959;
$gray-600: #403f3e;
$gray-200: #EFEFEF;
$gray-100: #F7F7F7;

// $xxxLightGray: lighten($gray, 72%);
$gray-300: lighten($gray-600, 72%);

// $xxLightGray: lighten($gray, 65%);
$gray-350: lighten($gray-600, 65%);

// $xLightGray: lighten($gray, 60%);
$gray-400: lighten($gray-600, 60%);

// $lightGray: lighten($gray, 40%);
$gray-450: lighten($gray-600, 40%);

$gray-500: #727272;


$slate-300: #849697;



// TEXT
$text-500: #878787;
$text-400: lighten($gray-500, 15%);





// Export color variables for use in React.
:export {
	orange400: $orange-400;
	orange500: $orange-500;
	primary: $orange-500;
	orange600: $orange-600;
	brown400: $brown-400;
	brown500: $brown-500;
	brown600: $brown-600;
	green400: $green-400;
	green450: $green-450;
	green500: $green-500;
	green600: $green-600;
	newGreen: $new-green;
	positive: $positive;
	red300: $red-300;
	red400: $red-400;
	red500: $red-500;
	negative: $red-500;
	positive:$positive;
	red600: $red-600;
	brightRed: $brightRed;
	teal400: $teal-400;
	teal500: $teal-500;
	secondary: $teal-500;
	orangeSecondary: $orange-secondary;
	teal600: $teal-600;
	yellow400: $yellow-400;
	yellow500: $yellow-500;
	warning: $yellow-500;
	yellow600: $yellow-600;
	transparent: $transparent;
	white100: $white-100;
	gray300: $gray-300;
	gray350: $gray-350;
	gray400: $gray-400;
	gray450: $gray-450;
	gray500: $gray-500;
	gray600: $gray-600;
	slate300: $slate-300;
	black500: $black-500;
	black900: $black-900;
	bluegray100: $blue-gray-100;
	bluegray300: $blue-gray-300;
	accent: $accent;
	newAccent: $new-accent;
	white200: $white-200;
}
