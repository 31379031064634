.carousel {
	font-family: Omnes-pro, Helvetica, Arial, sans-serif;

	.control-dots {
  	margin: 0px;
	}

	.slide {
		background-color: transparent;
		min-height: 370px;
		max-width: 90%
	}
}

.carousel-small {
	font-family: Omnes-pro, Helvetica, Arial, sans-serif;
}

.carouselRateTooltip {
	&.show {
		opacity: 1 !important;
	} 
	
	.tooltip-inner {
		background-color: #495F61;
		color: #FFFFFF;
		border: 1px solid #495F61;
	}
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before{
	border-top-color: #495F61 ;
}
