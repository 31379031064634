@import '../../../constants/colors';
@import '../../../styling/Animations.scss';

.cursor--pointer {
  cursor: pointer;
}

.expansion-item {
  .expansionTitle-border {
    border-top: 2px solid $gray-400;
  }

  &:last-child {
    border-bottom: 2px solid $gray-400;
  }

  .expansion__icon {
    @extend .anim__cubic--quick;
  }

  .collapsed {
    .expansion__icon {
      transform: rotate(180deg);
    }
  }
}
