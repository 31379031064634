@import '../../constants/colors';
@import '../../constants/weights';
@import '../../constants/spacing';

/**
 * FONT SIZES
 */
$font-xs: 0.75rem;
$font-sm: 0.875rem;
$font-base: 1rem;
$font-md: 1.1625rem;
$font-lg: 1.4375rem;
$font-xl: 1.8125rem;
$font-xxl: 2.15rem;
$font-xxxl: 2.4875rem;

/**
 * LEADING/LINE-HEIGHT
 */
$leading-none: 1;
$leading-tight: 1.25;
$leading-snug: 1.375;
$leading-normal: 1.5;
$leading-relaxed: 1.625;
$leading-loose: 2;

h1,
h2 {
  font-weight: $bold;
}

a {
  &.cc-color--orange-500,
  &.cc-color--primary {
    &:hover,
    &:visited {
      color: darken($primary, 10);
    }
  }

  &.cc-color--teal-400,
  &.cc-color--secondary {
    &:hover,
    &:visited {
      color: darken($teal-400, 10);
    }
  }
}

/**
 *  FONT SIZE CLASSES
 */
.font--xs {
  font-size: $s-3;
}
.font--sm {
  font-size: 0.875rem;
}
.font--base {
  font-size: $s-4;
}
.font--md {
  font-size: 1.1625rem;
}
.font--lg {
  font-size: 1.4375rem;
}
.font--xl {
  font-size: 1.8125rem;
}
.font--xxl {
  font-size: 2.15rem;
}
.font--xxxl {
  font-size: 2.4875rem;
}

/**
 *  FONT WEIGHT CLASSES
 */
.cc-font-weight--light {
  font-weight: $light;
}
.cc-font-weight--normal {
  font-weight: $normal;
}
.cc-font-weight--bold {
  font-weight: $bold;
}
.cc-font-weight--bolder {
  font-weight: $bolder;
}

/**
 *  LEADING/LINE HEIGHT CLASSES
 */
.leading--none {
  line-height: $leading-none;
}
.leading--tight {
  line-height: $leading-tight;
}
.leading--normal {
  line-height: $leading-normal;
}
