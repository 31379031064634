@import "../../../constants/_colors.scss";
@import "../../../constants/Borders.scss";
@import "../DefaultButton/Button.scss";

/********************************
 * CIRCLE / INFO BUTTON CLASSES *
 *******************************/

.cc-btn.cc-btn--circle {
  border-radius: $border-radius-full !important;
  font-size: $s-5;
  height: 2.25rem;
  width: 2.25rem;
  padding: 0;

  // SIZES
  &.cc-btn--sm {
    height: $s-6;
    width: $s-6;
    font-size: $s-4;

    & > .cc-btn__icon {
      margin-top: 2px;
    }
  }

  &.cc-btn--md {
    height: $s-7;
    width: $s-7;
    font-size: $s-5;
  }

  /**
   *  INFO BUTTON
   */
  &.cc-btn--info {
    box-shadow: none;
    font-size: 18px;

    & > .cc-btn__icon {
      margin-top: 2px;
    }

    // SIZES
    &.cc-btn--sm {
      & > .cc-btn__icon {
        margin-top: 3px;
      }
    }

    &.cc-btn--md {
      & > .cc-btn__icon {
        margin-top: 2px;
      }
    }

    // COLORS
    &.cc-btn--primary,
    &.cc-btn--secondary,
    &.cc-btn--orange-secondary,
    &.cc-btn--gray,
    &.cc-btn--white,
    &.cc-btn--positive,
    &.cc-btn--negative,
    &.cc-btn--warning,
    &.cc-btn--accent {
      background: transparent;
    }

    &.cc-btn--primary {
      color: $orange-500;

      &:hover,
      &:focus {
        background: rgba($orange-500, 0.2);
      }

      &:active {
        background-color: rgba($orange-500, 0.3);
      }
    }

    &.cc-btn--secondary {
      color: $teal-500;

      &:hover,
      &:focus {
        background: rgba($teal-500, 0.2);
      }

      &:active {
        background-color: rgba($teal-500, 0.3);
      }
    }

    &.cc-btn--orange-secondary {
      color: $orange-secondary;

      &:hover,
      &:focus {
        background: rgba($orange-secondary, 0.2);
      }

      &:active {
        background-color: rgba($orange-secondary, 0.3);
      }
    }

    &.cc-btn--gray {
      color: $blue-gray-300;

      &:hover,
      &:focus {
        background: rgba($blue-gray-300, 0.2);
      }

      &:active {
        background-color: rgba($blue-gray-300, 0.3);
      }
    }

    &.cc-btn--white {
      color: $white-100;

      &:hover,
      &:focus {
        background: rgba($white-100, 0.2);
      }

      &:active {
        background-color: rgba($white-100, 0.3);
      }
    }

    &.cc-btn--positive {
      color: $green-500;

      &:hover,
      &:focus {
        background: rgba($green-500, 0.2);
      }

      &:active {
        background-color: rgba($green-500, 0.3);
      }
    }

    &.cc-btn--negative {
      color: $red-500;

      &:hover,
      &:focus {
        background: rgba($red-500, 0.2);
      }

      &:active {
        background-color: rgba($red-500, 0.3);
      }
    }

    &.cc-btn--warning {
      color: -500;

      &:hover,
      &:focus {
        background: rgba($yellow-500, 0.2);
      }

      &:active {
        background-color: rgba($yellow-500, 0.3);
      }
    }

    &.cc-btn--accent {
      color: $accent;

      &:hover,
      &:focus {
        background: rgba($accent, 0.2);
      }

      &:active {
        background-color: rgba($accent, 0.3);
      }
    }
  }

  &.cc-btn.disabled.disabled {
    background: transparent;
    color: rgba($blue-gray-300, 0.7);
    box-shadow: none;
    border: none;
    cursor: not-allowed;
  }
}
