@import '../../../constants/colors';
@import '../../../constants/spacing';
@import '../../../constants/weights';
@import "../../../constants/Borders.scss";

@import '../../../styling/Animations';
@import '../../../styling/Shadows/Shadows.scss';

/***************************
 *    BASE BUTTON STYLE    *
 **************************/
button {
  cursor: pointer;

}

button:focus,
button:active,
.cc-btn:focus,
.cc-btn:active {
  box-shadow: none;
}

button:focus,
.cc-btn:focus {
  outline: 1px solid $accent;
}

.cc-btn {
  @extend .ripple;
  @extend .anim__cubic--quicker;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-family: 'Omnes-pro', 'Helvetica', 'Arial', sans-serif;
  font-weight: $normal;
  letter-spacing: 0.025rem;
  padding: $s-2 $s-6;
  border: 1px solid;
  border-color: transparent;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  /**
   *  DISABLED STATE
   */
  &.disabled {
    background: $gray-300;
    color: $blue-gray-300;
    box-shadow: none;
    cursor: not-allowed;

    &.ripple:after {
      display: none;
    }
  }
}

.cc-btn--reset-text {
  text-transform: unset;
}

/**********************
 *    BUTTON SIZES    *
 *********************/
.cc-btn--sm {
  padding: $s-2;
}

.cc-btn--md,
.cc-btn--lg,
.cc-btn--xl {
  font-size: $s-4;
}

.cc-btn--lg,
.cc-btn--xl {
  @extend .cc-shadow--light-2;
}

.cc-btn--lg {
  padding-top: $s-3;
  padding-bottom: $s-3;
}

.cc-btn--xl {
  padding-top: $s-4;
  padding-bottom: $s-4;
}

.cc-btn--full {
  width: 100%;
}

.cc-btn.cc-btn--rounded {
  border-radius: $border-radius-full !important;
}

/****************************
 *    BUTTON VARIANT STYLES    *
 ***************************/
/**
 * FILLED BUTTON CLASS
 */
.cc-btn--filled {
  @extend .cc-shadow--light-2;

  // PRIMARY
  &.cc-btn--primary {
    background-color: $orange-500;
    color: lighten($orange-500, 90%);

    &:hover,
    &:focus {
      background-color: lighten($orange-500, 10%);
      color: lighten($orange-500, 99%);
      box-shadow: 0 1px 8px rgba($orange-500, 0.4), 0 3px 4px rgba($orange-500, 0.14),
        0 3px 3px -2px rgba($orange-500, 0.12);
      text-decoration: none;
    }

    &:active {
      background-color: darken($orange-500, 3%);
      box-shadow: none;
    }
  }

  // SECONDARY
  &.cc-btn--secondary {
    background-color: $teal-500;
    color: lighten($teal-500, 90%);

    &:hover,
    &:focus {
      background-color: lighten($teal-500, 5%);
      color: lighten($teal-500, 99%);
      box-shadow: 0 1px 8px rgba($teal-500, 0.4), 0 3px 4px rgba($teal-500, 0.14), 0 3px 3px -2px rgba($teal-500, 0.12);
    }

    &:active {
      background-color: darken($teal-500, 3%);
      box-shadow: none;
    }
  }

  // ORANGE SECONDARY
  &.cc-btn--orange-secondary {
    background-color: $orange-secondary;
    color: lighten($orange-secondary, 90%);

    &:hover,
    &:focus {
      background-color: lighten($orange-secondary, 5%);
      color: lighten($orange-secondary, 99%);
      box-shadow: 0 1px 8px rgba($orange-secondary, 0.4), 0 3px 4px rgba($orange-secondary, 0.14), 0 3px 3px -2px rgba($orange-secondary, 0.12);
    }

    &:active {
      background-color: darken($orange-secondary, 3%);
      box-shadow: none;
    }
  }

  // BLUE GRAY
  &.cc-btn--bluegray {
    background-color: $blue-gray-300;
    color: lighten($blue-gray-300, 90%);

    &:hover,
    &:focus {
      background-color: lighten($blue-gray-300, 10%);
      color: lighten($blue-gray-300, 99%);
      box-shadow: 0 1px 8px rgba($blue-gray-300, 0.4), 0 3px 4px rgba($blue-gray-300, 0.14),
        0 3px 3px -2px rgba($blue-gray-300, 0.12);
    }

    &:active {
      background-color: darken($blue-gray-300, 3%);
    }
  }

  // Light Orange
  &.cc-btn--light-orange {
    background-color: #FF9B55;
    color: white;

    &:hover,
    &:focus {
      background-color: lighten(#FF9B55, 10%);
      color: white;
      text-decoration: none;
    }

    &:active {
      background-color: lighten(#FF9B55, 10%);
      color: white;
      text-decoration: none;
    }
  }

  // GRAY
  &.cc-btn--gray {
    background-color: lighten($gray-500, 20%);
    color: lighten($gray-500, 90%);

    &:hover,
    &:focus {
      background-color: lighten($gray-500, 10%);
      color: lighten($gray-500, 99%);
      box-shadow: 0 1px 8px rgba($gray-500, 0.4), 0 3px 4px rgba($gray-500, 0.14), 0 3px 3px -2px rgba($gray-500, 0.12);
    }

    &:active {
      background-color: darken($gray-500, 3%);
    }
  }

  // WHITE
  &.cc-btn--white {
    background-color: $white-100;
    color: $gray-600;

    &:hover,
    &:focus {
      background-color: lighten($white-100, 10%);
      color: lighten($gray-600, 10%);
      box-shadow: 0 1px 8px rgba($gray-600, 0.4), 0 3px 4px rgba($gray-600, 0.14), 0 3px 3px -2px rgba($gray-600, 0.12);
    }
    &:active {
      background-color: darken($white-100, 3%);
    }
  }

  // POSITIVE
  &.cc-btn--positive {
    background-color: $green-500;
    color: lighten($green-500, 90%);

    &:hover,
    &:focus {
      background-color: lighten($green-500, 10%);
      color: lighten($green-500, 99%);
      box-shadow: 0 1px 8px rgba($green-500, 0.4), 0 3px 4px rgba($green-500, 0.14),
        0 3px 3px -2px rgba($green-500, 0.12);
    }

    &:active {
      background-color: darken($green-500, 3%);
      box-shadow: none;
    }
  }

  // NEGATIVE
  &.cc-btn--negative {
    background-color: $red-500;
    color: lighten($red-500, 90%);

    &:hover,
    &:focus {
      background-color: lighten($red-500, 10%);
      color: lighten($red-500, 99%);
      box-shadow: 0 1px 8px rgba($red-500, 0.4), 0 3px 4px rgba($red-500, 0.14), 0 3px 3px -2px rgba($red-500, 0.12);
    }

    &:active {
      background-color: darken($red-500, 3%);
      box-shadow: none;
    }
  }

  // WARNING
  &.cc-btn--warning {
    background-color: $yellow-500;
    color: lighten($yellow-500, 90%);

    &:hover,
    &:focus {
      background-color: lighten($yellow-500, 10%);
      color: lighten($yellow-500, 95%);
      box-shadow: 0 1px 8px rgba($yellow-500, 0.4), 0 3px 4px rgba($yellow-500, 0.14),
        0 3px 3px -2px rgba($yellow-500, 0.12);
    }

    &:active {
      background-color: darken($yellow-500, 3%);
      box-shadow: none;
    }
  }

  // LIGHT TEAL
  &.cc-btn--light-teal {
    background-color: $teal-400;
    color: lighten($teal-400, 90%);

    &:hover,
    &:focus {
      background-color: lighten($teal-400, 5%);
      color: lighten($teal-400, 95%);
      box-shadow: 0 1px 8px rgba($teal-400, 0.4), 0 3px 4px rgba($teal-400, 0.14), 0 3px 3px -2px rgba($teal-400, 0.12);
    }

    &:active {
      background-color: darken($teal-400, 3%);
      box-shadow: none;
    }
  }


  // Dark Blue
  &.cc-btn--dark-blue {
    background-color: #0C2C48;
    color: lighten(#0C2C48, 90%);

    &:hover,
    &:focus {
      background-color: lighten(#0C2C48, 5%);
      color: lighten(#0C2C48, 95%);
      box-shadow: 0 1px 8px rgba(#0C2C48, 0.4), 0 3px 4px rgba(#0C2C48, 0.14), 0 3px 3px -2px rgba(#0C2C48, 0.12);
    }

    &:active {
      background-color: darken(#0C2C48, 3%);
      box-shadow: none;
    }
  }

  // Dark Brand Green
  &.cc-btn--dark-green {
    background-color: #003936;
    color: lighten(#003936, 90%);

    &:hover,
    &:focus {
      background-color: lighten(#003936, 5%);
      color: lighten(#003936, 95%);
      box-shadow: 0 1px 8px rgba(#003936, 0.4), 0 3px 4px rgba(#003936, 0.14), 0 3px 3px -2px rgba(#003936, 0.12);
    }

    &:active {
      background-color: darken(#003936, 3%);
      box-shadow: none;
    }
  }

  // Dark Brand Red
  &.cc-btn--dark-red {
    background-color: #980412;
    color: lighten(#980412, 90%);

    &:hover,
    &:focus {
      background-color: lighten(#980412, 5%);
      color: lighten(#980412, 95%);
      box-shadow: 0 1px 8px rgba(#980412, 0.4), 0 3px 4px rgba(#980412, 0.14), 0 3px 3px -2px rgba(#980412, 0.12);
    }

    &:active {
      background-color: darken(#980412, 3%);
      box-shadow: none;
    }
  }

  // ACCENT
  &.cc-btn--accent {
    background-color: $accent;
    color: lighten($accent, 90%);

    &:hover,
    &:focus {
      background-color: lighten($accent, 5%);
      color: lighten($accent, 95%);
      box-shadow: 0 1px 8px rgba($accent, 0.4), 0 3px 4px rgba($accent, 0.14), 0 3px 3px -2px rgba($accent, 0.12);
    }

    &:active {
      background-color: darken($accent, 3%);
      box-shadow: none;
    }
  }

  // DISABLED
  &.cc-btn.disabled {
    background-color: rgba($gray-600, 0.1);
    color: rgba($blue-gray-300, 0.7);
    box-shadow: none;
    cursor: not-allowed;
    outline: none;
  }
}

/************************
 * OUTLINE BUTTON CLASS *
 ***********************/
.cc-btn--outline {
  background: transparent;
  box-shadow: none;

  // PRIMARY
  &.cc-btn--primary {
    border: 1px solid $orange-500;
    color: $orange-500;

    &:hover,
    &:focus {
      background: rgba($orange-500, 0.2);
    }

    &:active {
      background: rgba($orange-500, 0.3);
    }
  }

  // SECONDARY
  &.cc-btn--secondary {
    border: 1px solid $teal-500;
    color: $teal-500;

    &:hover,
    &:focus {
      background: rgba($teal-500, 0.2);
    }

    &:active {
      background: rgba($teal-500, 0.3);
    }
  }

  // ORANGE SECONDARY
  &.cc-btn--orange-secondary {
    border: 1px solid $orange-secondary;
    color: $orange-secondary;

    &:hover,
    &:focus {
      background: rgba($orange-secondary, 0.2);
    }

    &:active {
      background: rgba($orange-secondary, 0.3);
    }
  }

  // BLUE-GRAY
  &.cc-btn--bluegray {
    border: 1px solid $blue-gray-300;
    color: $blue-gray-300;

    &:hover,
    &:focus {
      background: rgba($blue-gray-300, 0.2);
    }

    &:active {
      background: rgba($blue-gray-300, 0.3);
    }
  }

  // GRAY
  &.cc-btn--gray {
    border: 1px solid $gray-500;
    color: $gray-500;

    &:hover,
    &:focus {
      background: rgba($gray-500, 0.2);
    }

    &:active {
      background: rgba($gray-500, 0.3);
    }
  }

  // WHITE
  &.cc-btn--white {
    border: 1px solid $white-100;
    color: $white-100;

    &:hover,
    &:focus {
      background: rgba($gray-400, 0.2);
    }

    &:active {
      background: rgba($gray-400, 0.3);
    }
  }

  // POSITIVE
  &.cc-btn--positive {
    border: 1px solid $green-500;
    color: $green-500;

    &:hover,
    &:focus {
      background: rgba($green-500, 0.2);
    }

    &:active {
      background: rgba($green-500, 0.3);
    }
  }

  // NEGATIVE
  &.cc-btn--negative {
    border: 1px solid $red-500;
    color: $red-500;

    &:hover,
    &:focus {
      background: rgba($red-500, 0.2);
    }

    &:active {
      background: rgba($red-500, 0.3);
    }
  }

  // WARNING
  &.cc-btn--warning {
    border: 1px solid $yellow-500;
    color: $yellow-500;

    &:hover,
    &:focus {
      background: rgba($yellow-500, 0.2);
    }

    &:active {
      background: rgba($yellow-500, 0.3);
    }
  }

  // LIGHT TEAL
  &.cc-btn--light-teal {
    border: 1px solid $teal-400;
    color: $teal-400;

    &:hover,
    &:focus {
      background: rgba($teal-400, 0.2);
    }

    &:active {
      background: rgba($teal-400, 0.3);
    }
  }

  // ACCENT
  &.cc-btn--accent {
    border: 1px solid $accent;
    color: $accent;

    &:hover,
    &:focus {
      background: rgba($accent, 0.2);
    }

    &:active {
      background: rgba($accent, 0.3);
    }
  }

  // DISABLED
  &.cc-btn.disabled {
    background: transparent;
    border: 1px solid rgba($blue-gray-300, 0.5);
    color: rgba($blue-gray-300, 0.7);
    box-shadow: none;
    cursor: not-allowed;
  }
}

/*********************
 * FLAT BUTTON CLASS *
 ********************/
.cc-btn--flat {
  background: transparent;
  box-shadow: none;

  &.cc-btn--primary {
    color: $orange-500;

    &:hover,
    &:focus {
      background-color: rgba($orange-500, 0.2);
    }

    &:active {
      background-color: rgba($orange-500, 0.3);
    }
  }

  &.cc-btn--secondary {
    color: $teal-500;

    &:hover,
    &:focus {
      background-color: rgba($teal-500, 0.2);
    }

    &:active {
      background-color: rgba($teal-500, 0.3);
    }
  }

  &.cc-btn--orange-secondary {
    color: $orange-secondary;

    &:hover,
    &:focus {
      background-color: rgba($orange-secondary, 0.2);
    }

    &:active {
      background-color: rgba($orange-secondary, 0.3);
    }
  }

  &.cc-btn--bluegray {
    color: $blue-gray-300;

    &:hover,
    &:focus {
      background-color: rgba($blue-gray-300, 0.2);
    }

    &:active {
      background-color: rgba($blue-gray-300, 0.3);
    }
  }

  &.cc-btn--gray {
    color: $gray-500;

    &:hover,
    &:focus {
      background-color: rgba($gray-500, 0.2);
    }

    &:active {
      background-color: rgba($gray-500, 0.3);
    }
  }

  &.cc-btn--white {
    color: $white-100;

    &:hover,
    &:focus {
      background-color: rgba($white-100, 0.2);
    }

    &:active {
      background-color: rgba($white-100, 0.3);
    }
  }

  &.cc-btn--positive {
    color: $green-500;

    &:hover,
    &:focus {
      background-color: rgba($green-500, 0.2);
    }

    &:active {
      background-color: rgba($green-500, 0.3);
    }
  }

  &.cc-btn--negative {
    color: $red-500;

    &:hover,
    &:focus {
      background-color: rgba($red-500, 0.2);
    }

    &:active {
      background-color: rgba($red-500, 0.3);
    }
  }

  &.cc-btn--warning {
    color: -500;

    &:hover,
    &:focus {
      background-color: rgba($yellow-500, 0.2);
    }

    &:active {
      background-color: rgba($yellow-500, 0.3);
    }
  }

  &.cc-btn--accent {
    color: $accent;

    &:hover,
    &:focus {
      background-color: rgba($accent, 0.2);
    }

    &:active {
      background-color: rgba($accent, 0.3);
    }
  }

  &.cc-btn.disabled {
    background: transparent;
    // border-color: transparent;
    color: rgba($blue-gray-300, 0.7);
    box-shadow: none;
    cursor: not-allowed;
  }
}

/********************************
 *  PREFIX/SUFFIX BUTTON ICONS  *
 *******************************/
.cc-btn__icon {
  font-size: 87.5%;
}

.cc-btn__icon--prefix {
  margin-right: $s-3;
  margin-top: 3px;
}

.cc-btn__icon--suffix {
  margin-left: $s-3;
  margin-top: 3px;
}
