@media (min-width: 992px) {
  #app-wrapper-content-wrapper {
    padding-top: 96px; // 65px to 106px;

    .guide-sticky-nav {
      top: 108px; // 77px to 108px
    }

    .home-search__sidebar {
      height: calc(100vh - 165px); //  - home search menus
    }

    .homesearch-filters__cc-btn-wrapper--all-filters .dropdown-menu {
      max-height: calc(100vh - 60px - 106px);
    }
  }

  .cc-nav-mobile-dropdown-menu {
    display: none;
  }

  .cc-nav-2023 {
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    display:flex;
    z-index: 1039;
    top: 0;
    left: 0;
    position: fixed;

    .cc-nav-top-level-container {
      display: block;

      .cc-nav-top-level-container-brand {
        width: auto;
        padding-right: .5rem;
        img {
          display: none;
        }
      }

      .cc-nav-top-level-container-items {
        padding-left: 0;

        li.industry-menu-item {
          padding: 5px .9rem;
          width: auto;
          max-width: 140px;
          font-size: 16px;
        }

      }
    }

    .cc-nav-container {
      //background-color: rgb(67 87 89);
      background-color: white;
      border-style: solid;
      border-color: rgb(188 202 203);
      border-bottom-width: 1px;
      border-top: none;
      border-left: none;
      border-right: none;
      width: 100%;
      z-index: 20;
      height: 70px;
      padding: 0;

      .cc-nav-row {
        padding-left: 2.5em;
        padding-right: 2.5em;
        justify-content:space-between;
        align-items:center;
        flex-wrap: wrap;
        max-width: 1440px;
        height: 100%;
        display: flex;
        margin-left: auto;
        margin-right: auto;

        ul.cc-nav-list {
          column-gap: 0.5rem;
          align-items: center;
          height: 100%;
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;

          li {

            &.cc-nav-brand {
              color: rgb(67 87 89);
              font-family: 'acumin-pro', Arial, sans-serif;
              line-height: 24px;
              text-align: left;
              width: 90px;
              font-size: 16px;
              margin-right: 0px;

              a {
                height: 100%;
                font-family: acumin-pro;
                font-size: 1rem;
                line-height: 1.5rem;
              }

              svg {
                width: 25px;
              }
            }

            &.menu-item {
              padding-left: 0px;
              padding-right: 0px;
              //color: rgb(255 255 255);
              color: rgb(12 44 72);
              border-color: transparent;
              border-bottom-width: 1px;
              align-items: center;
              cursor: pointer;
              height: 100%;
              display: flex;
              font-family: "proxima-nova";
              font-size: 15px;
              line-height: 1.25rem;
              min-width: 30px;

              a {
                padding-left: 8px;
                padding-right: 8px;
              }

              &.company {
                min-width: 110px;
              }

              &:hover {
                background-color: rgb(255 245 237);
                color: rgb(12 44 72);
                cursor: pointer;

                border-image-outset: 0 0 6px 0;
                border-image-repeat: repeat;
                border-image-slice: 10 0 10 0;
                border-image-source: url('../BottomBorderSVG.svg');
                border-image-width: 0 0 10px 0;
                border-style: solid;
                border-top: none;
                border-left: none;
                border-right: none;
              }

              div {
                width: 100%;
                text-align: center;
              }
            }

          }
        }

        .cc-nav-right {
          height: 100%;
          ul.cc-nav-list-right {
            column-gap: 1rem;
            height: 100%;
            list-style: none;
            margin: 0;
            padding: 0;

            .vertical-line{
              width: 1px;
              background-color: rgb(12, 44, 72);
              height: 28px;
              margin-top: 18px;
              margin-left: 10px;
              margin-right: 10px;
            }

            .cc-nav-item-right {
              a {
                font-size: 16px;
                color: rgb(12, 44, 72);
                border-bottom: 2px solid transparent;
                text-decoration: none;
                text-align: center;
                margin: 16px 0rem;
                background-color: rgb(255 255 255);
                justify-content: center;
                align-items: center;
                height: 38px;
                display: flex;
                position: relative;
                overflow: hidden;
                position: relative;

                &:hover {
                  border-bottom: 2px solid #EA701B;
                  text-decoration: none;
                }
              }
            }

            .menu-item-accounts {
              flex-shrink: 0;
              display: flex;
              align-items: center;
              height: 100%;
              padding-bottom: 3px;
              button {
                color: rgb(255 255 255);
                //color: #ea701b;
                text-align: center;
                padding: 10px;
                //background-color: rgb(255 255 255);
                background-color: #ea701b;
                border-width: 1px;
                justify-content: center;
                align-items: center;
                height: 34px;
                display: flex;
                position: relative;
                overflow: hidden;
                position: relative;
                transition-timing-function: cubic-bezier(.4,0,1,1);
                transition-duration: .2s;
                font-size: 15px;
                line-height: 1.5rem;
                //border-color: rgb(255 255 255);
                border-color: #ea701b;
                border-radius: 9999px;
                border-style: solid;

                &:hover {
                  background-color: #B44100;
                }
              }
            }

            .menu-item-hamburger {
              display: none;
              height: 100%;

              button {
                padding: 0.5rem;
                border-radius: 9999px;
                justify-content: center;
                align-items: center;
                display: flex;
              }
            }

          }
        }

      }
    }



    .cc-nav-dropdown-menu {
      display: flex;
      top: 4rem;
      transition-timing-function: cubic-bezier(.4,0,.2,1);
      transition-duration: .15s;
      transition-property: all;
      background-color: rgb(255 255 255);
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
      overflow: hidden;
      width: 100%;
      height: fit-content;
      z-index: 10;
      position: absolute;

      .cc-nav-dropdown-menu-container {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        flex-wrap: nowrap;
        padding-bottom: 2.5rem;
        padding-top: 2.5rem;
        align-content: center;
        max-width: 1440px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display:none;

        &.open {
          display: flex;
        }

        &.flex-wrap {
          flex-wrap: wrap;
        }

        .cc-nav-dropdown-menu-left-column {
          width: 100%;
          align-items: center;
          display: flex;

          .cc-nav-dropdown-menu-card-group {
            flex-wrap: wrap;
            row-gap: 1rem;
            margin-bottom: 0;
            gap: 1rem;
            justify-content: center;
            display: flex;
            width: 100%;

            .cc-nav-dropdown-menu-card {
              padding: 0.75rem 1rem;
              flex-wrap: nowrap;
              width: 100%;
              min-width: 400px;
              max-width: 450px;
              height: auto;
              transition-timing-function: cubic-bezier(.4,0,.2,1);
              transition-duration: .15s;
              transition-property: all;
              text-align: center;
              color: rgb(67 87 89);
              text-decoration:none;
              background-color: rgb(255 255 255);
              border-color: rgb(235 241 242);
              border-style: solid;
              border-width: 1px;
              border-radius: 0.25rem;
              align-items: center;
              align-content: center;
              cursor: pointer;
              flex-shrink: 0;
              display: flex;

              &:hover {
                background-color: rgb(255, 245, 237);
                border-color: rgb(234, 112, 27);
              }

              .cc-nav-dropdown-menu-card-icon {
                overflow: hidden;
                justify-content: center;
                align-items: flex-start;
                width: 2.25rem;
                height: 2.25rem;
                display: flex;
                margin-right: 0.75rem;
                margin-bottom: 0;
              }

              .cc-nav-dropdown-menu-card-info {
                text-align: left;
                width: 100%;
                display: block;

                .cc-nav-dropdown-menu-card-title {
                  color: rgb(12 44 72);
                  width: 100%;
                  font-weight: 700;
                  font-family: acumin-pro;
                  font-size: .95rem;
                  line-height: 1.25rem;
                  margin: 0;
                }
                .cc-nav-dropdown-menu-card-caption {
                  width: 100%;
                  font-family: acumin-pro;
                  font-size: .75rem;
                  font-weight: 400;
                  line-height: 1rem;
                  margin: 0;
                }
              }

            }

            .cc-nav-dropdown-menu-card-company {
              padding: 0.75rem 1rem;
              flex-wrap: nowrap;
              width: 100%;
              max-width: 450px;
              height: auto;
              transition-timing-function: cubic-bezier(.4,0,.2,1);
              transition-duration: .15s;
              transition-property: all;
              text-align: center;
              color: rgb(67 87 89);
              text-decoration:none;
              background-color: rgb(255 255 255);
              border-color: rgb(235 241 242);
              border-style: solid;
              border-width: 1px;
              border-radius: 0.25rem;
              align-items: center;
              align-content: center;
              cursor: pointer;
              flex-shrink: 0;
              display: flex;

              &:hover {
                background-color: rgb(255, 245, 237);
                border-color: rgb(234, 112, 27);
              }

              .cc-nav-dropdown-menu-card-icon {
                overflow: hidden;
                justify-content: center;
                align-items: flex-start;
                width: 2.25rem;
                height: 2.25rem;
                display: flex;
                margin-right: 0.75rem;
                margin-bottom: 0;
              }

              .cc-nav-dropdown-menu-card-info {
                text-align: left;
                width: 100%;
                display: block;

                .cc-nav-dropdown-menu-card-title {
                  color: rgb(12 44 72);
                  width: 100%;
                  font-weight: 700;
                  font-family: acumin-pro;
                  font-size: .95rem;
                  line-height: 1.25rem;
                  margin: 0;
                }
                .cc-nav-dropdown-menu-card-caption {
                  width: 100%;
                  font-family: acumin-pro;
                  font-size: .75rem;
                  font-weight: 400;
                  line-height: 1rem;
                  margin: 0;
                }
              }

            }

          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  #app-wrapper-content-wrapper {
    padding-top: 96px; // 65px to 106px;

    .guide-sticky-nav {
      top: 108px; // 77px to 108px
    }

    .home-search__sidebar {
      height: calc(100vh - 165px); //  - home search menus
    }

    .homesearch-filters__cc-btn-wrapper--all-filters .dropdown-menu {
      max-height: calc(100vh - 60px - 106px);
    }
  }

  .cc-nav-mobile-dropdown-menu {
    display: none;
  }

  .cc-nav-2023 {
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    display:flex;
    z-index: 1039;
    top: 0;
    left: 0;
    position: fixed;

    .cc-nav-top-level-container {
      display: block;


      .cc-nav-top-level-container-brand {
        margin-left: 2rem;
        margin-right: 0;
        img {
          display: inline;
          width: 80px;
        }
      }

      .cc-nav-top-level-container-items {
        padding-left: 15px;

        li.industry-menu-item {
          padding: 5px 1.25rem;
          font-size: 16px;
        }

        .cc-nav-top-level-container-items-right {
          margin-right: 2rem;
          li.industry-menu-item {
            width: 120px;
          }
        }
      }
    }

    .cc-nav-container {
      //background-color: rgb(67 87 89);
      background-color: white;
      border-style: solid;
      border-color: rgb(188 202 203);
      border-bottom-width: 1px;
      border-top: none;
      border-left: none;
      border-right: none;
      width: 100%;
      z-index: 20;
      height: 70px;
      padding: 0;

      .cc-nav-row {
        padding-left: 2.5em;
        padding-right: 2.5em;
        justify-content:space-between;
        align-items:center;
        flex-wrap: wrap;
        max-width: 1440px;
        height: 100%;
        display: flex;
        margin-left: auto;
        margin-right: auto;

        ul.cc-nav-list {
          column-gap: 1rem;
          align-items: center;
          height: 100%;
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;

          li {

            &.cc-nav-brand {
              color: rgb(67 87 89);
              font-family: 'acumin-pro', Arial, sans-serif;
              line-height: 24px;
              text-align: left;
              width: 115px;
              font-size: 16px;
              margin-right: 15px;

              a {
                height: 100%;
                font-family: acumin-pro;
                font-size: 1rem;
                line-height: 1.5rem;
              }

              svg {
                width: 25px;
              }
            }

            &.menu-item {
              padding-left: 0px;
              padding-right: 0px;
              //color: rgb(255 255 255);
              color: rgb(12 44 72);
              border-color: transparent;
              border-bottom-width: 1px;
              align-items: center;
              cursor: pointer;
              height: 100%;
              display: flex;
              font-family: "proxima-nova";
              font-size: 17px;
              line-height: 1.25rem;
              min-width: 30px;

              a {
                padding-left: 8px;
                padding-right: 8px;
              }

              &.company {
                min-width: 110px;
              }

              &:hover {
                background-color: rgb(255 245 237);
                color: rgb(12 44 72);
                cursor: pointer;

                border-image-outset: 0 0 6px 0;
                border-image-repeat: repeat;
                border-image-slice: 10 0 10 0;
                border-image-source: url('../BottomBorderSVG.svg');
                border-image-width: 0 0 10px 0;
                border-style: solid;
                border-top: none;
                border-left: none;
                border-right: none;
              }

              div {
                width: 100%;
                text-align: center;
              }
            }

          }
        }

        .cc-nav-right {
          height: 100%;
          ul.cc-nav-list-right {
            column-gap: 1rem;
            height: 100%;
            list-style: none;
            margin: 0;
            padding: 0;

            .vertical-line{
              width: 1px;
              background-color: rgb(12, 44, 72);
              height: 28px;
              margin-top: 18px;
              margin-left: 10px;
              margin-right: 10px;
            }
            
            .cc-nav-item-right {
              a {
                font-size: 16px;
                color: rgb(12, 44, 72);
                border-bottom: 2px solid transparent;
                text-decoration: none;
                text-align: center;
                margin: 16px 0rem;
                background-color: rgb(255 255 255);
                justify-content: center;
                align-items: center;
                height: 38px;
                display: flex;
                position: relative;
                overflow: hidden;
                position: relative;
                
                &:hover {
                  border-bottom: 2px solid #EA701B;
                  text-decoration: none;
                }
              }
            }

            .menu-item-accounts {
              flex-shrink: 0;
              display: flex;
              align-items: center;
              height: 100%;
              padding-bottom: 3px;
              button {
                color: rgb(255 255 255);
                //color: #ea701b;
                text-align: center;
                padding: 10px 2rem;
                //background-color: rgb(255 255 255);
                background-color: #ea701b;
                border-width: 1px;
                justify-content: center;
                align-items: center;
                height: 34px;
                display: flex;
                position: relative;
                overflow: hidden;
                position: relative;
                transition-timing-function: cubic-bezier(.4,0,1,1);
                transition-duration: .2s;
                font-size: 1rem;
                line-height: 1.5rem;
                //border-color: rgb(255 255 255);
                border-color: #ea701b;
                border-radius: 9999px;
                border-style: solid;

                &:hover {
                  background-color: #B44100;
                }
              }
            }

            .menu-item-hamburger {
              display: none;
              height: 100%;

              button {
                padding: 0.5rem;
                border-radius: 9999px;
                justify-content: center;
                align-items: center;
                display: flex;
              }
            }

          }
        }

      }
    }



    .cc-nav-dropdown-menu {
      display: flex;
      top: 4rem;
      transition-timing-function: cubic-bezier(.4,0,.2,1);
      transition-duration: .15s;
      transition-property: all;
      background-color: rgb(255 255 255);
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
      overflow: hidden;
      width: 100%;
      height: fit-content;
      z-index: 10;
      position: absolute;

      .cc-nav-dropdown-menu-container {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        flex-wrap: nowrap;
        padding-bottom: 2.5rem;
        padding-top: 2.5rem;
        align-content: center;
        max-width: 1440px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display:none;

        &.open {
          display: flex;
        }

        &.flex-wrap {
          flex-wrap: wrap;
        }

        .cc-nav-dropdown-menu-left-column {
          width: 100%;
          align-items: center;
          display: flex;

          .cc-nav-dropdown-menu-card-group {
            flex-wrap: wrap;
            row-gap: 1rem;
            margin-bottom: 0;
            gap: 1rem;
            justify-content: center;
            display: flex;
            width: 100%;

            .cc-nav-dropdown-menu-card {
              padding: 0.75rem 1rem;
              flex-wrap: nowrap;
              width: 100%;
              min-width: 400px;
              max-width: 450px;
              height: auto;
              transition-timing-function: cubic-bezier(.4,0,.2,1);
              transition-duration: .15s;
              transition-property: all;
              text-align: center;
              color: rgb(67 87 89);
              text-decoration:none;
              background-color: rgb(255 255 255);
              border-color: rgb(235 241 242);
              border-style: solid;
              border-width: 1px;
              border-radius: 0.25rem;
              align-items: center;
              align-content: center;
              cursor: pointer;
              flex-shrink: 0;
              display: flex;

              &:hover {
                background-color: rgb(255, 245, 237);
                border-color: rgb(234, 112, 27);
              }

              .cc-nav-dropdown-menu-card-icon {
                overflow: hidden;
                justify-content: center;
                align-items: flex-start;
                width: 2.25rem;
                height: 2.25rem;
                display: flex;
                margin-right: 0.75rem;
                margin-bottom: 0;
              }

              .cc-nav-dropdown-menu-card-info {
                text-align: left;
                width: 100%;
                display: block;

                .cc-nav-dropdown-menu-card-title {
                  color: rgb(12 44 72);
                  width: 100%;
                  font-weight: 700;
                  font-family: acumin-pro;
                  font-size: .95rem;
                  line-height: 1.25rem;
                  margin: 0;
                }
                .cc-nav-dropdown-menu-card-caption {
                  width: 100%;
                  font-family: acumin-pro;
                  font-size: .75rem;
                  font-weight: 400;
                  line-height: 1rem;
                  margin: 0;
                }
              }

            }

            .cc-nav-dropdown-menu-card-company {
              padding: 0.75rem 1rem;
              flex-wrap: nowrap;
              width: 100%;
              max-width: 450px;
              height: auto;
              transition-timing-function: cubic-bezier(.4,0,.2,1);
              transition-duration: .15s;
              transition-property: all;
              text-align: center;
              color: rgb(67 87 89);
              text-decoration:none;
              background-color: rgb(255 255 255);
              border-color: rgb(235 241 242);
              border-style: solid;
              border-width: 1px;
              border-radius: 0.25rem;
              align-items: center;
              align-content: center;
              cursor: pointer;
              flex-shrink: 0;
              display: flex;

              &:hover {
                background-color: rgb(255, 245, 237);
                border-color: rgb(234, 112, 27);
              }

              .cc-nav-dropdown-menu-card-icon {
                overflow: hidden;
                justify-content: center;
                align-items: flex-start;
                width: 2.25rem;
                height: 2.25rem;
                display: flex;
                margin-right: 0.75rem;
                margin-bottom: 0;
              }

              .cc-nav-dropdown-menu-card-info {
                text-align: left;
                width: 100%;
                display: block;

                .cc-nav-dropdown-menu-card-title {
                  color: rgb(12 44 72);
                  width: 100%;
                  font-weight: 700;
                  font-family: acumin-pro;
                  font-size: .95rem;
                  line-height: 1.25rem;
                  margin: 0;
                }
                .cc-nav-dropdown-menu-card-caption {
                  width: 100%;
                  font-family: acumin-pro;
                  font-size: .75rem;
                  font-weight: 400;
                  line-height: 1rem;
                  margin: 0;
                }
              }

            }

          }
        }
      }
    }
  }
}

@media (min-width: 1400px) {

  .cc-nav-2023 {
    .cc-nav-container {
      .cc-nav-row {
        max-width: 100%;
        ul.cc-nav-list {
          li {
            &.cc-nav-brand {
              margin-right: 20px;
              a {
              }

              svg {
              }
            }

            &.menu-item {
              min-width: 30px;
              padding-left: 0px;
              padding-right: 0px;

              a {
                padding-left: 15px;
                padding-right: 15px;
              }

              &:hover {
              }

              div {
              }
            }

          }
        }

        .cc-nav-right {
          ul.cc-nav-list-right {
            .menu-item-accounts {
              margin-left: 1.5rem;
              button {
                &:hover {
                }
              }
            }

            .menu-item-hamburger {
              button {
              }
            }

          }
        }

      }
    }



    .cc-nav-dropdown-menu {
      .cc-nav-dropdown-menu-container {
        &.open {
        }

        .cc-nav-dropdown-menu-left-column {
          width: auto;

          .cc-nav-dropdown-menu-card-group {
            flex-wrap: nowrap;
            row-gap: 1rem;
            margin-bottom: 0;
            gap: 1rem;
            justify-content: center;
            display: flex;

            .cc-nav-dropdown-menu-card {
              padding: 0.75rem 1.25rem;
              flex-wrap: wrap;
              min-width: auto;
              width: 198px;
              height: 172px;
              transition-timing-function: cubic-bezier(.4,0,.2,1);
              transition-duration: .15s;
              transition-property: all;
              text-align: center;
              color: rgb(67 87 89);
              text-decoration:none;
              background-color: rgb(255 255 255);
              border-color: rgb(235 241 242);
              border-style: solid;
              border-width: 1px;
              border-radius: 0.25rem;
              align-items: center;
              align-content: center;
              cursor: pointer;
              flex-shrink: 0;
              display: flex;

              &:hover {
                background-color: rgb(255, 245, 237);
                border-color: rgb(234, 112, 27);
              }

              .cc-nav-dropdown-menu-card-icon {
                margin-bottom: 0.5rem;
                margin-left: auto;
                margin-right: auto;
                overflow: hidden;
                justify-content: center;
                align-items: flex-start;
                width: 2.25rem;
                height: 2.25rem;
                display: flex;
              }

              .cc-nav-dropdown-menu-card-info {
                text-align: center;
                width: 100%;

                .cc-nav-dropdown-menu-card-title {
                  color: rgb(12 44 72);
                  width: 100%;
                  font-weight: 700;
                  font-family: acumin-pro;
                  font-size: .95rem;
                  line-height: 1.25rem;
                  margin: 0;
                }
                .cc-nav-dropdown-menu-card-caption {
                  width: 100%;
                  font-family: acumin-pro;
                  font-size: .75rem;
                  font-weight: 400;
                  line-height: 1rem;
                }
              }

            }

            .cc-nav-dropdown-menu-card-company {
              padding: 0.75rem 1rem;
              flex-wrap: nowrap;
              width: 300px;
              height: 60px;
              transition-timing-function: cubic-bezier(.4,0,.2,1);
              transition-duration: .15s;
              transition-property: all;
              text-align: center;
              color: rgb(67 87 89);
              text-decoration:none;
              background-color: rgb(255 255 255);
              border-color: rgb(235 241 242);
              border-style: solid;
              border-width: 1px;
              border-radius: 0.25rem;
              align-items: center;
              align-content: center;
              cursor: pointer;
              flex-shrink: 0;
              display: flex;
        
              &:hover {
                background-color: rgb(255, 245, 237);
                border-color: rgb(234, 112, 27);
              }
        
              .cc-nav-dropdown-menu-card-icon {
                overflow: hidden;
                justify-content: center;
                align-items: flex-start;
                width: 2.25rem;
                height: 2.25rem;
                display: flex;
                margin-right: 0.75rem;
                margin-bottom: 0;
              }
        
              .cc-nav-dropdown-menu-card-info {
                text-align: left;
                width: 100%;
                display: block;
        
                .cc-nav-dropdown-menu-card-title {
                  color: rgb(12 44 72);
                  width: 100%;
                  font-weight: 700;
                  font-family: acumin-pro;
                  font-size: 16px;
                  line-height: 20px;
                  margin: 0;
                }
                .cc-nav-dropdown-menu-card-caption {
                  width: 100%;
                  font-family: acumin-pro;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16px;
                  margin: 0;
                }
              }
        
            }

          }
        }
      }
    }
  }
}