

.modal.clean-modal .modal-dialog.cc-nav-modal-diaglog {
  .modal-content {
    .modal-header {
      .modal-title {
        font-size: 1.2em;
      }
    }
  }
}

.cc-nav-sign-in-modal-group {
  flex-wrap: wrap;
  row-gap: 1rem;
  margin-bottom: 0;
  gap: 1rem;
  justify-content: center;
  display: flex;

  .cc-nav-sign-in-modal-column {
    flex-wrap: wrap;
    row-gap: 1rem;
    margin-bottom: 0;
    gap: 1rem;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(235 241 242);

    &:last-child {
      border-bottom: none;
    }

    h4 {
      color: rgb(12 44 72);
      width: 100%;
      font-weight: 700;
      font-family: acumin-pro;
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 -8px 0;
    }

    .cc-nav-sign-in-modal-card {
      padding: 0.75rem 1rem;
      flex-wrap: nowrap;
      width: 100%;
      height: auto;
      transition-timing-function: cubic-bezier(.4,0,.2,1);
      transition-duration: .15s;
      transition-property: all;
      text-align: center;
      color: rgb(67 87 89);
      text-decoration:none;
      background-color: rgb(255 255 255);
      border-color: rgb(235 241 242);
      border-style: solid;
      border-width: 1px;
      border-radius: 0.25rem;
      align-items: center;
      align-content: center;
      cursor: pointer;
      flex-shrink: 0;
      display: flex;

      &:hover {
        background-color: rgb(255, 245, 237);
        border-color: rgb(234, 112, 27);
      }

      .cc-nav-dropdown-menu-card-icon {
        overflow: hidden;
        justify-content: center;
        align-items: flex-start;
        width: 2.25rem;
        height: 2.25rem;
        display: flex;
        margin-right: 0.75rem;
        margin-bottom: 0;
      }

      .cc-nav-dropdown-menu-card-info {
        text-align: left;
        width: 100%;
        display: block;

        .cc-nav-dropdown-menu-card-title {
          color: rgb(12 44 72);
          width: 100%;
          font-weight: 700;
          font-family: acumin-pro;
          font-size: 16px;
          line-height: 20px;
          margin: 0;
        }
        .cc-nav-dropdown-menu-card-caption {
          width: 100%;
          font-family: acumin-pro;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          margin: 0;
        }
      }

    }
  }

}

@media (min-width: 576px) {
  .modal-dialog.cc-nav-modal-diaglog {
    max-width: 500px;
  }
}

@media (min-width: 768px) {
  .modal-dialog.cc-nav-modal-diaglog {
    max-width: 600px;
  }
}

@media (min-width: 1024px) {
  .modal-dialog.cc-nav-modal-diaglog {
    max-width: 600px;
  }

  .clean-modal.modal.show {
    .modal-title {
      text-align: center;
      width: 100%;
    }
    .modal-body {
      padding-bottom: 30px;
    }
  }

  .cc-nav-sign-in-modal-group {
    flex-wrap: nowrap;
    row-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    display: flex;
    margin-left: 15px;
    margin-bottom: -30px;

    .cc-nav-sign-in-modal-column {
      align-content: flex-start;
      border-bottom: none;
      border-right: 1px solid rgb(235 241 242);
      padding-right: 15px;
      padding-bottom: 30px;

      &:last-child {
        border-right: none;
      }

      h4 {
        color: rgb(12 44 72);
        width: 100%;
        font-weight: 700;
        font-family: acumin-pro;
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 -10px 0;
        text-align: center;
      }

      .cc-nav-sign-in-modal-card {
        padding: 0.75rem 1.25rem;
        flex-wrap: wrap;
        min-width: auto;
        height: 172px;
        transition-timing-function: cubic-bezier(.4,0,.2,1);
        transition-duration: .15s;
        transition-property: all;
        text-align: center;
        color: rgb(67 87 89);
        text-decoration:none;
        background-color: rgb(255 255 255);
        border-color: rgb(235 241 242);
        border-style: solid;
        border-width: 1px;
        border-radius: 0.25rem;
        align-items: center;
        align-content: center;
        cursor: pointer;
        flex-shrink: 0;
        display: flex;

        &:hover {
          background-color: rgb(255, 245, 237);
          border-color: rgb(234, 112, 27);
        }

        .cc-nav-dropdown-menu-card-icon {
          margin-bottom: 0.5rem;
          margin-left: auto;
          margin-right: auto;
          overflow: hidden;
          justify-content: center;
          align-items: flex-start;
          width: 2.25rem;
          height: 2.25rem;
          display: flex;
        }

        .cc-nav-dropdown-menu-card-info {
          text-align: center;
          width: 100%;

          .cc-nav-dropdown-menu-card-title {
            color: rgb(12 44 72);
            width: 100%;
            font-weight: 700;
            font-family: acumin-pro;
            font-size: .95rem;
            line-height: 1.25rem;
            margin: 0;
          }
          .cc-nav-dropdown-menu-card-caption {
            width: 100%;
            font-family: acumin-pro;
            font-size: .75rem;
            font-weight: 400;
            line-height: 1rem;
          }
        }

      }
    }

  }
}

@media (min-width: 992px) {
  .modal-dialog.cc-nav-modal-diaglog {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-dialog.cc-nav-modal-diaglog {
    max-width: 1000px;
  }
}