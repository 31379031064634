// Constant styles
.landing-page {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
  // display: flex;

  // figcaption {
  //   text-align: center;
  // }

  .available-counties {

  }

  .available-states {

  }

}

.seo-wrapper-breadcrumb {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
}

// Keep these at the bottom, it sets priority
@import './QuoteAppSeoWrapperDesktops.scss';
@import './QuoteAppSeoWrapperMobile.scss';