@import '@capcenter/shared/src/styling/Breakpoints.scss';

.rtb {
  min-height: 300px;
  font-family: 'acumin-pro', Arial, sans-serif;
}
.rtb-text-block {
  background-color: white;


  .rtb-title {
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: left;
    font-family: Omnes-pro, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 52px;
    color: #0C2C48;
  }
  .rtb-sub-title {
    color: #EA701B;
    font-size: 1.2em;
    padding: 10px 0 5px 0;
  }
  .rtb-body {
    text-align: left;
    font-family: Omnes-pro, Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-style: normal;
    font-weight: 400;
    color: #213035;
    margin-bottom: 50px;

    a {
      color: #EA701B;
    }
  }

  .btn-lg {
    text-align: left;
    padding: 0 0 20px 0;
    font-size: 20px;
    line-height: 40px;
    font-family: Omnes-pro, Helvetica, Arial, sans-serif;
  }

  .key-data-card {
    margin-top: 15px;
    padding: 30px 30px;
  }

  .key-data-title {
    font-size: 35px;
    font-weight: 600;
    color: #EA701B;
    margin-bottom: 10px;
    font-family: Omnes-pro, Helvetica, Arial, sans-serif;
  }

  .key-data-caption {
    font-size: 18px;
    line-height: 22px;
    font-family: Omnes-pro, Helvetica, Arial, sans-serif;
  }

  .key-data-details {
    margin-top: 40px;
    font-size: 12px;
    line-height: 14px;
    font-family: Omnes-pro, Helvetica, Arial, sans-serif;
  }
}

.number-one-rtb {

  .number-one-left-col {
    margin-top: 15px;
    padding: 30px 0px 30px 0px;

    p {
      margin-left: 0px;
      margin-right: 0px;

      &.large {
        margin-left: 0;
        margin-right: 0;
      }
    }

    ul {
      margin-left: 15px;
      margin-bottom: 15px;
    }
  }

  .number-one-right-col {
    margin-top: 30px;
    padding: 10px 0 0 0;
  }

  .number-one-title {
    font-size: 35px;
    line-height: 52px;
    color: #EA701B;
    font-weight: 700;
    font-family: 'Omnes-pro', Arial, sans-serif;
  }

  .number-one-caption {
    font-size: 28px;
    line-height: 30px;
    margin-left: 70px;
    margin-bottom: 20px;
    color: #0C2C48;
    font-weight: 700;
    font-family: 'Omnes-pro', Arial, sans-serif;
  }

  p {
    font-weight: 500;
    font-family: 'Omnes-pro', Arial, sans-serif;

    &.large {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .number-one-title-2 {
    font-size: 30px;
    line-height: 33px;
    color: #0C2C48;
    margin: 0 0 20px 0;
    font-weight: 700;
    font-family: 'Omnes-pro', Arial, sans-serif;
  }

  .number-one-caption-2 {
    font-size: 20px;
    line-height: 22px;
  }
}

@media only screen and (min-width: $small) {
  .number-one-rtb {
    .number-one-title {
      font-size: 45px;
      line-height: 52px;
      color: #EA701B;
      font-weight: 700;
      font-family: 'Omnes-pro', Arial, sans-serif;
    }

    .number-one-caption {
      margin-left: 135px;
    }
  }

  .number-one-left-col {
    margin-right: 15px;

    p {
      margin-left: 40px;
      margin-right: 40px;

      &.large {
      }
    }
  }
}

@media only screen and (min-width: $medium) {
  .rtb {
    min-height: 380px;
  }
}

@media only screen and (min-width: $xLarge) {
  .rtb {
    min-height: 380px;
  }

  .number-one-rtb {
    .number-one-right-col {
      margin-left: 15px;
    }
  }
}
