@import '../../constants/colors';
@import '../../constants/weights';

html {
  background-color: $black-500;
}

.navbar--wrap {
  position: relative;
}
.navbar {
  height: 50px;
  padding: 0px;
  z-index: 100;
  position: absolute;
  width: 100%;
  top: 0;
  background-color: transparent;
  z-index: 9999;
}

.nav--pad-child {
  background-color: $gray-300;
}

.nav--pad-child-pad {
  padding-top: 50px;
}

.navbar-brand {
  color: $white-100;
  font-weight: $bold;
  margin-right: 0px;
  svg {
    color: $orange-500;
  }
}

.navbar-dark {
  background-color: $gray-600;
  .navbar-toggler {
    border: 0;
    color: $white-100;
  }
}
.navbar-toggler {
  font-size: 150%;
  width: 36px;
  color: $white-100;
}

.nav--collapse {
  transform: translate(-100%, 0);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 285px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: 0.25s ease-in-out;
  z-index: 9999;
  font-size: 80%;
  font-weight: $normal;
  background-color: $white-100;
}

@media (min-width: 768px) {
  .nav--collapse {
    font-size: 100%;
  }
}

.nav--background {
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  transition: 0.25s ease-in-out;
  z-index: 9999;
  cursor: pointer;
  pointer-events: none;
}

.nav--open {
  .nav--background {
    pointer-events: all;
  }
  .nav--collapse {
    transform: translate(0, 0);
  }
  .nav--background {
    opacity: 1;
  }
}

.lgi-indent {
  padding-left: 2.3rem;
}
