@import '../../styling/Breakpoints';

div[id^='tippy'], #tippy-1, #tippy-2 {
  z-index: 1038!important;
}

.cc--fixed-banner {
  z-index: 1039;
  position: fixed;
  height: 360px;
  background-color: #EA701B;
  padding: 10px;

  &.hide-banner {
    top: -360px;
    transition: top 0.5s;
  }

  .close-banner {
    i {
      margin-right: 5px;
    }

    font-family: 'omnes-pro';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    color: white;

    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 15px;

    &:hover {
      color: #B44100;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .image-group {
    display: none;
  }

  .text-group {
    padding: 12px 20px;
    width: 100%;
    display: block;

    .pre-title {
      font-family: 'proxima-nova';
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      letter-spacing: 3.28px;
      color: white;
    }

    hr {
      width: 40px;
      color: white;
      margin: 5px 0 10px 0;
      border: 2px solid white;
      margin-bottom: 10px;
    }

    .title {
      font-family: 'proxima-nova';
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.516129px;
      color: #FFFFFF;
    }

    .sub-title {
      font-family: 'acumin-pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      padding-top: 10px;
    }
  }

  .btn-group {
    width: 100%;
    display: block;
    padding: 0 20px;

    a {
      color: #FFFFFF;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }

    button {
      font-family: 'acumin-pro';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #FFFFFF;
      background-color: #EA701B;
      border: 2px solid white;
      border-radius: 2px;
      padding: 10px;
      margin-bottom: 15px;
      width: 100%;
      display:block;

      &:hover {
        background-color: #B44100;
      }

      &.btn-primary {
        background-color: #B44100;
        &:hover {
          background-color: #642400;
        }
      }
    }
  }
}

.cc--mega-menu-wrap.fixed-top, #cc--navigation-menu-2023 {
  z-index: 1039;
  transition: top 0.3s;

  &.show-banner {
    top: 360px;

    .cc-nav-mobile-dropdown-menu {
      max-height: calc(100vh - 65px - 360px);
      padding-bottom: 100px;
    }
  }
}

#app-wrapper-content-wrapper {
  transition: padding-top 0.3s;
  &.show-banner {
    padding-top: 415px;

    .guide-sticky-nav {
      top: 300px;
    }

    .home-search__sidebar {
      height: calc(100vh - 164px - 360px); //  - home search menus - 236px banner
    }

    .homesearch-filters__cc-btn-wrapper--all-filters .dropdown-menu {
      max-height: calc(100vh - 109px - 55px - 80px - 360px);
    }
  }
}

@media (min-width: $medium) {

  #app-wrapper-content-wrapper {
    &.show-banner {
      .guide-sticky-nav {
        top: 300px;
      }

      .home-search__sidebar {
        height: calc(100vh - 164px - 236px); //  - home search menus - 236px banner
      }
    }
  }

  .cc--fixed-banner {
    padding: 20px;
    height:236px;

    .image-group {
      display: none;
    }

    .text-group {
      padding-top: 0;
      min-width: 440px;
      max-width: 460px;
      width: 440px;
      margin-right: 300px;
    }

    .btn-group {
      position:absolute;
      top:50px;
      right: 10px;
      width: 300px;
      display:block;
    }
  }

  .cc--mega-menu-wrap.fixed-top.show-banner, #cc--navigation-menu-2023.show-banner {
    top:236px;

    .cc-nav-mobile-dropdown-menu {
      max-height: calc(100vh - 65px - 236px);
      padding-bottom: 100px;
    }
  }

  #app-wrapper-content-wrapper.show-banner {
    padding-top:290px;

    .homesearch-filters__cc-btn-wrapper--all-filters .dropdown-menu {
      max-height: calc(100vh - 109px - 55px - 80px - 236px);
    }
  }
}

@media (min-width: $large) {
  .cc--fixed-banner {
    padding: 20px;
    height:236px;

    .image-group {
      display: block;
      width: 200px;
      height: 236px;
      margin-top:-20px;
      margin-left:-5px;
      background-image: url('./banner-image.jpg');
    }

    .text-group {
      padding-top: 0px;
      min-width: 400px;
      max-width: 490px;
      width: auto;
      margin-right: 250px;
    }

    .btn-group {
      min-width: 250px;
      max-width: 350px;
      position:absolute;
      top:50px;
      right: 10px;
      display:block;
    }
  }

  .cc--mega-menu-wrap.fixed-top.show-banner, #cc--navigation-menu-2023.show-banner {
    top:236px;

    .cc-nav-mobile-dropdown-menu {
      max-height: calc(100vh - 65px - 236px);
      padding-bottom: 100px;
    }
  }

  #app-wrapper-content-wrapper.show-banner {
    padding-top:290px;

    .home-search__sidebar {
      height: calc(100vh - 59px - 55px - 236px); //  - 59px - 55px - 236px
    }

    .homesearch-filters__cc-btn-wrapper--all-filters .dropdown-menu {
      max-height: calc(100vh - 109px - 55px - 80px - 236px);
    }
  }
}

@media (min-width: 1200px) {
  .cc--mega-menu-wrap.fixed-top.show-banner, #cc--navigation-menu-2023.show-banner {
    top:236px;
  }

  #app-wrapper-content-wrapper.show-banner {

    /* mega menu v1
    padding-top:290px;

    .home-search__sidebar {
      height: calc(100vh - 59px - 55px - 236px); //  - 59px - 55px - 236px
    }

    .homesearch-filters__cc-btn-wrapper--all-filters .dropdown-menu {
      max-height: calc(100vh - 109px - 55px - 80px - 236px);
    }
     */

    // mega menu v2
    /*
    padding-top:300px;

    .home-search__sidebar {
      height: calc(100vh - 59px - 65px - 236px); //  - 59px - 65px - 236px
    }

    .homesearch-filters__cc-btn-wrapper--all-filters .dropdown-menu {
      max-height: calc(100vh - 109px - 65px - 80px - 236px);
    }*/

    // mega menu v3
    padding-top:341px;

    .home-search__sidebar {
      height: calc(100vh - 59px - 106px - 236px); //  - 59px - 65px - 236px
    }

    .homesearch-filters__cc-btn-wrapper--all-filters .dropdown-menu {
      max-height: calc(100vh - 109px - 106px - 80px - 236px);
    }
  }
}

@media (min-width: $xLarge) {

  .cc--fixed-banner {
    padding: 35px;
    height:206px;

    .image-group {
      display: block;
      width: 350px;
      height: 206px;
      margin-top:-35px;
      margin-left:-35px;
      background-image: url('./banner-image-wide.jpg');
    }

    .text-group {
      padding-top: 0px;
      margin-top: -10px;
      min-width: 520px;
      max-width: 520px;
      width: auto;
      margin-right: 250px;
    }

    .btn-group {
      width: 300px;
      position:absolute;
      top:50px;
      right: 60px;
      display:block;
    }
  }

  .cc--mega-menu-wrap.fixed-top.show-banner, #cc--navigation-menu-2023.show-banner {
    top:206px;
  }


  /* mega menu v1
  #app-wrapper-content-wrapper.show-banner {
    padding-top:260px;

    .guide-sticky-nav {
      top: 270px;
    }

    .home-search__sidebar {
      height: calc(100vh - 59px - 55px - 206px); //  - 59px - 55px - 206px
    }
    .homesearch-filters__cc-btn-wrapper--all-filters .dropdown-menu {
      max-height: calc(100vh - 109px - 55px - 80px - 206px);
    }
  }
  */

  // mega menu v2
  /*
  #app-wrapper-content-wrapper.show-banner {
    padding-top:270px;

    .guide-sticky-nav {
      top: 280px;
    }

    .home-search__sidebar {
      height: calc(100vh - 59px - 65px - 206px); //  - 59px - 65px - 206px
    }
    .homesearch-filters__cc-btn-wrapper--all-filters .dropdown-menu {
      max-height: calc(100vh - 60px - 65px - 206px);
    }
    .saved-search-alert--dropdown-wrapper .dropdown-menu {
      max-height: calc(100vh - 60px - 65px - 206px);
    }
  }*/

  // mega menu v3
  #app-wrapper-content-wrapper.show-banner {
    padding-top:311px;

    .guide-sticky-nav {
      top: 231px;
    }

    .home-search__sidebar {
      height: calc(100vh - 59px - 106px - 206px); //  - 59px - 65px - 206px
    }
    .homesearch-filters__cc-btn-wrapper--all-filters .dropdown-menu {
      max-height: calc(100vh - 60px - 106px - 206px);
    }
    .saved-search-alert--dropdown-wrapper .dropdown-menu {
      max-height: calc(100vh - 60px - 106px - 206px);
    }
  }
}
