@import "~@capcenter/shared/src/constants/colors";

body {
  max-width: 1680px;
  margin: 0 auto;
}
  
hr {
  border-color: $gray-350;
}
