@import '../../styling/Breakpoints';
@import "../../../../../node_modules/@capcenter/shared/src/constants/colors";

.modal.clean-modal {
	z-index: 9999!important;

	&.fade.show {
		opacity: 1!important;
	}
	.modal-dialog {
		background-color: white;
		border-radius: 4px;

		.modal-content {
			border: none;

			.modal-header {
				border:none;
				padding: 1rem 1rem 0rem 1rem;
				padding-right: 20px;

				.modal-title {
					font-family: 'proxima-nova';
					font-size: 1.5em;
					color: #0C2C48;
				}

				.close {
					border: none;
					background-color: transparent;
				}
			}
			.modal-body {
				font-family: 'acumin-pro';
				opacity: 100%;
			}

			.modal-footer {
				justify-content: left;
				border:none;
				padding: 0rem 1rem 1rem 1rem;

				button {
					border-radius: 2px;
					color: white;
					background-color: #EA701B;
					width: 250px;
					height: 35px;
					border: none;
					font-weight: normal;
					font-family: 'acumin-pro';
					font-size: 1em;
				}
			}
		}
	}
}



@media (min-width: $small) {
	.modal-dialog {
		max-width: 500px;
	}
}

@media (min-width: $medium) {
	.modal-dialog {
		max-width: 600px;
	}
}

@media (min-width: $large) {
	.modal-dialog {
		max-width: 800px;
	}
}

@media (min-width: $xLarge) {
	.modal-dialog {
		max-width: 1000px;
	}
}