.cc-nav-2023 {
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    display:flex;
    z-index: 1039;
    top: 0;
    left: 0;
    position: fixed;
    box-shadow: 0px 0px 10px -3px #849697;

    .cc-nav-dropdown-menu {
      display: none;
    }

    .cc-lower-menu-nav-brand:hover{
      cursor: default;
    }

    .cc-nav-top-level-container {
      display: none;
      background-color: #0C2C48;
      color: white;
      width: 100%;
      height: 36px;

      .cc-nav-top-level-container-brand {
        margin-left: 1.5rem;
        padding-top: 5px;
        display: inline-block;
        width: 150px;
        line-height: 24px;

        a {
          text-decoration: none;
        }

        svg {
          display: inline-block;
        }

        img {
          margin-left: 5px;
          width: 100px;
        }
      }
      .cc-nav-top-level-container-items {
        display: inline-block;

        li {
          display: inline-block;
          width: 120px;
          padding: 5px 10px 5px 10px;
          text-align: center;
          font-size: 17px;
          color: white;

          a {
            color: white;
            width: 100%;
            display: block;
            line-height: 26px;
            text-decoration: none;

            &:hover {
              text-decoration: none;
            }
          }

          &:hover, &.active {
            background-color: white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            a {
              color: rgb(12, 44, 72);
            }
          }
        }

        &.cc-nav-top-level-container-items-right {
          margin-right: 1.5rem;
          position: absolute;
          right: 0;

          li.telephone-number {
            i {
              margin-right: 5px;
            }

            width: 200px;

            &:hover {
              background-color: transparent;

              a {
                color: white;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .cc-nav-container {
      //background-color: rgb(67 87 89);
      background-color: white;
      border-style: solid;
      border-color: rgb(188 202 203);
      border-bottom-width: 1px;
      border-top: none;
      border-left: none;
      border-right: none;
      width: 100%;
      z-index: 20;
      padding: 3px 0;

      .cc-nav-row {
        padding-left: 1em;
        padding-right: 0.25em;
        justify-content:space-between;
        align-items:center;
        flex-wrap: wrap;
        max-width: 1440px;
        height: 100%;
        display: flex;
        margin-left: auto;
        margin-right: auto;

        ul.cc-nav-list {
          column-gap: 1.5rem;
          align-items: center;
          height: 100%;
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            &.cc-nav-brand {
              display: list-item;
              color: rgb(67 87 89);
              font-family: 'acumin-pro', Arial, sans-serif;
              line-height: 24px;
              text-align: left;
              font-size: 16px;

              a {
                height: 100%;
                font-family: acumin-pro;
                font-size: 1rem;
                line-height: 1.5rem;
                vertical-align: middle;
                color: #333333;
              }

              svg {
                height: 20px;
                width: 20px;
                margin-right: 2px;
                margin-top: 1px;
              }

              div {
                display: inline-block;
                margin-left: 0px;
                font-family: 'acumin-pro', Arial, sans-serif;
                font-size: 16px;
              }
            }

            &.menu-item {
              display: none;

              padding-left: 0rem;
              padding-right: 0rem;
              //color: rgb(255 255 255);
              color: rgb(12 44 72);
              border-color: transparent;
              border-bottom-width: 1px;
              align-items: center;
              cursor: pointer;
              height: 100%;
              font-weight: 400;
              font-family: "proxima-nova";
              font-size: 17px;
              line-height: 1.25rem;
              min-width: 120px;

              &:hover {
                background-color: rgb(255 245 237);
                color: rgb(12 44 72);
                cursor: pointer;

                border-image-outset: 0 0 6px 0;
                border-image-repeat: repeat;
                border-image-slice: 10 0 10 0;
                border-image-source: url('../BottomBorderSVG.svg');
                border-image-width: 0 0 10px 0;
                border-style: solid;
                border-top: none;
              }

              div {
                width: 100%;
                text-align: center;
              }
              a {
                color: rgb(12 44 72);
                text-decoration: none;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
              }
            }

          }
        }

        .cc-nav-right {
          height: 100%;

          ul.cc-nav-list-right {
            display: flex;
            flex-direction: row;
            column-gap: 1.5rem;
            height: 100%;
            list-style: none;
            margin: 0;
            padding: 0;
            
            .cc-nav-item-right {
              a {
                display:none;
                font-family: 'Acumin-Pro';
              }
            }

            .menu-item-accounts {
              flex-shrink: 0;
              display: none;
              align-items: center;
              height: 100%;

              button {
                color: white;
                text-align: center;
                padding: 8px 2rem;
                background-color: #ea701b;
                border-width: 1px;
                justify-content: center;
                align-items: center;
                height: 34px;
                display: flex;
                position: relative;
                overflow: hidden;
                position: relative;
                transition-timing-function: cubic-bezier(.4,0,1,1);
                transition-duration: .2s;
                font-size: 1rem;
                line-height: 1.5rem;
                //border-color: rgb(255 255 255);
                border-color: #ea701b;
                border-radius: 9999px;
                border-style: solid;

                &:hover {
                  background-color: #B44100;
                }
              }
            }

            .menu-item-hamburger {
              display: block;
              height: 100%;

              button {
                padding: 0.5rem;
                border-radius: 9999px;
                justify-content: center;
                align-items: center;
                display: flex;
                cursor: pointer;
                border: none;
                background-color:transparent;
                color: rgb(67, 87, 89);
                text-align: center;

                &:hover {
                  background-color:rgb(246, 246, 246);
                }
                &:active, &:focus {
                  border: none;
                  outline: none;
                }

                div {
                  margin: 5px;
                  width: 25px;
                  height: 25px;
                }
              }
            }

          }
        }

      }
    }

    .cc-nav-mobile-dropdown-menu {
      background-color: white;
      width: 100%;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
      overflow: auto;
      height: fit-content;

      .cc-nav-mobile-dropdown-menu-container {
        background-color: rgb(246 246 246);
        align-content: center;
        flex-wrap: wrap;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        .cc-nav-mobile-dropdown-menu-accordion {
          width: 100%;
          max-height: calc(100vh - 55px);

          .cc-nav-mobile-dropdown-menu-accordion-menu-item {
            overflow: hidden;
            height: 35px;

            &:focus, &:active {
              background-color: transparent;
            }

            &.open {
              background-color: rgb(255, 245, 237);
              height: auto;

              button, a.industry-menu {
                background-color: transparent;
                display:inline-block;

                svg {
                  transform: rotate(180deg);
                  transition-timing-function: cubic-bezier(.4,0,.2,1);
                  transition-duration: .15s;
                  transition-property: all;
                }
              }

              .cc-nav-mobile-dropdown-menu-accordion-menu-inner-panel {
                display:flex;
              }
            }

            a.industry-menu {
              vertical-align:top;
              width: 100%;
              height: 35px;
              background-color: white;
              border: none;
              color: rgb(12 44 72);
              text-align: left;
              padding: 9px 1.5rem 6px 1rem;
              font-weight: 700;
              font-family: acumin-pro;
              font-size: 16px;
              display:inline-block;
              margin-top:0;
              text-decoration: none;

              &:hover {
                background-color: rgb(255 245 237);
                text-decoration: underline;
              }
            }

            button {
              height: 35px;
              background-color: white;
              border: none;
              color: rgb(12 44 72);
              text-align: right;
              padding: 9px 1.5rem 6px 1rem;
              font-weight: 700;
              font-family: acumin-pro;
              font-size: 16px;
              line-height: 20px;
              display:none;

              &:hover {
                background-color: rgb(255 245 237);
              }

              &:focus, &:active {
                background-color: rgb(255 245 237);
                outline: none;
                border:none;
              }

              svg {
                float:right;
                transition-timing-function: cubic-bezier(.4,0,.2,1);
                transition-duration: .15s;
                transition-property: all;
                display: block;
                vertical-align: middle;
              }
            }

            span {
              vertical-align: top;
              line-height: 18px;
            }
          }

          .cc-nav-mobile-dropdown-menu-accordion-menu-inner-panel {
            display:none;
            transition-timing-function: cubic-bezier(.4,0,.2,1);
            transition-duration: .15s;
            transition-property: all;
            font-size: .875rem;
            line-height: 1.25rem;
            padding-top: 0.75rem;
            padding-bottom: 0.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
            overflow: hidden;
            flex-wrap: wrap;
            height: 100%;

            .cc-nav-mobile-dropdown-menu-accordion-menu-inner-panel-card-group-outer {

              align-items: center;
              width: 100%;
              display: flex;

              .cc-nav-mobile-dropdown-menu-accordion-menu-inner-panel-card-group-inner {
                row-gap: 0.5rem;
                gap: 0;
                justify-content: space-between;
                flex-wrap: wrap;
                display: flex;
                margin-bottom: 0;
                width: 100%;

                .cc-nav-mobile-dropdown-menu-card {
                  margin-bottom: 10px;
                  transition-timing-function: cubic-bezier(.4,0,.2,1);
                  transition-duration: .15s;
                  transition-property: all;
                  text-align: center;
                  padding: 0.75rem;
                  background-color: rgb(255 255 255);
                  border: 1px solid #FF9B55;
                  border-width: 1px;
                  border-radius: 0.25rem;
                  align-items: center;
                  align-content: center;
                  cursor: pointer;
                  flex-shrink: 0;
                  width: 100%;
                  height: auto;
                  //display: flex;
                  color: rgb(67 87 89);

                  a {
                    margin-left: 20px;
                    text-decoration: none;
                    display:flex;
                    padding: 5px 20px;

                    &:hover {
                      .cc-nav-mobile-dropdown-menu-card-title {
                        color: rgb(234 112 27);
                      }
                    }
                  }

                  /*
                  &:hover {
                    background-color: rgb(255, 245, 237);
                    border: 1px solid rgb(234 112 27);
                    text-decoration: none;
                  }*/

                  .cc-nav-mobile-dropdown-menu-card-icon {
                    overflow: hidden;
                    justify-content: center;
                    align-items: center;
                    width: 25px;
                    height: 25px;
                    padding: 5px;
                    display: flex;
                    margin-right: 0.75rem;
                    margin-bottom: 0;
                  }

                  .cc-nav-mobile-dropdown-menu-card-info {
                    text-align: left;
                    width: 100%;
                    align-items: center;
                    display:flex;

                    .cc-nav-mobile-dropdown-menu-card-title {
                      align-items: center;
                      color: rgb(12 44 72);
                      width: 100%;
                      color: #0c2c48;
                      font-weight: 700;
                      font-family: acumin-pro;
                      font-size: 15px;
                      line-height: 18px;
                      margin: 0;
                    }
                  }
                }
              }
            }

            .cc-nav-mobile-dropdown-menu-accordion-menu-inner-panel-bottom-section {
              flex-wrap: wrap;
              width: 100%;
              display: flex;

              .cc-nav-mobile-dropdown-menu-accordion-menu-inner-panel-divider {
                background-color: rgb(188 202 203);
                width: 100%;
                height: 1px;
                display: block;
                margin-bottom: 1rem;
                margin-top: 1rem;

                &.d-none {
                  display: none;
                }
              }

              .cc-nav-mobile-dropdown-menu-accordion-menu-inner-panel-text-menu {
                align-self: center;
                width: 100%;
                height: fit-content;
                display: flex;
                margin-bottom: 1rem;
                padding: 0 15px;

                .cc-nav-mobile-dropdown-menu-accordion-menu-inner-panel-text-menu-header {
                    color: rgb(12 44 72);
                    font-weight: 600;
                    white-space: nowrap;
                    margin-bottom: 1rem;
                    font-family: acumin-pro;
                    font-size: 12px;
                    line-height: 16px;
                }

                .cc-nav-mobile-dropdown-menu-accordion-menu-inner-panel-text-menu-item {
                  margin-bottom: 0.75rem;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  a {
                    color: rgb(33 48 53);
                    white-space: wrap;
                    z-index: 10;
                    position: relative;
                    font-family: acumin-pro;
                    font-size: 14px;
                    line-height: 20px;
                    text-decoration: inherit;

                    &:hover {
                      background-color: rgb(255 245 237);
                      margin: -5px -15px;
                      padding: 5px 15px;
                    }
                  }
                }
              }
            }

          }


          .cc-nav-mobile-dropdown-menu-accounts-div {
            margin-top: 3px;
            padding: 1.2rem;
            padding-bottom: 0rem;
            justify-content: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: white;
            border-top: 1px solid #BCCACB;

            & > div {
              max-width: 25rem;
              width: 100%;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 10px;
              text-align: center;

              .cc-nav-mobile-dropdown-menu-accounts-button {
                background-color: white;
                font-family: 'acumin-pro', Arial, sans-serif;
                text-align: center;
                padding: 0.25rem 2rem;
                border-width: 1px;
                width: 100%;
                position: relative;
                overflow: hidden;
                transition-timing-function: cubic-bezier(.4,0,1,1);
                transition-duration: .2s;
                outline: 2px solid transparent;
                outline-offset: 2px;
                font-size: 1rem;
                line-height: 1.5rem;
                border-color: rgb(234 112 27);
                color: rgb(234 112 27);
                border-radius: 9999px;
                cursor: pointer;
                border-style: solid;

                font-weight: 700;
                font-size: 14px;

                &:hover {
                  background-color: rgb(255 245 237);
                }
              }
              .cc-nav-mobile-dropdown-menu-accounts-button-solid-background {
                /* Brand Orange/500 */
                background: #EA701B;
                border-color: #EA701B;
                /* white / white */
                color: #FFFFFF;
              }

              .cc-nav-mobile-dropdown-menu-accounts-button-solid-background:hover{
                  /* Brand Orange/300 */
                  background: #B44100;
                  border-color: #B44100;
              }

              .cc-nav-mobile-dropdown-menu-accounts-button-solid-background:focus{
                /* Brand Orange/500 */
                background: #EA701B;

                border: 1px solid #FFFFFF;
                box-shadow: 0px 0px 5px #0C7BE1;
              }

              .cc-nav-mobile-dropdown-menu-accounts-button-solid-background:active{
                /* Brand Orange/300 */
                background: #B44100!important;
                border-color: #B44100!important;
                box-shadow: inset 0px 0px 8px rgba(33, 48, 53, 0.35);
              }

              .cc-nav-mobile-dropdown-menu-accounts-button-solid-background:disabled{
                /* Slate/300 */
                background: #849697;
                border-color: #849697;
              }
            }
            .cc-nav-mobile-dropdown-menu-accounts-link {
              font-family: 'Acumin-Pro';
              font-size: 16px;
              color: rgb(12, 44, 72);
              border-bottom: 2px solid transparent;
              text-decoration: none;
              text-align: center;
              
              &:hover {
                border-bottom: 2px solid #EA701B;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

// Keep these at the bottom, it sets priority
@import './MegaMenuPhones.scss';
@import './MegaMenuTablets.scss';
@import './MegaMenuDesktops.scss';