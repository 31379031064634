/**
 *  HOW TO USE:
 *  Simply import this Colors.scss file into another .scss file
 *  and use "@extend: .[classname]" to extend any pre-made class to
 *  another class
 *  
 * @extends docs: https://sass-lang.com/documentation/at-rules/extend
 */

@import '../../constants/_colors.scss';

/***************************************
 *   TEXT & BACKGROUND COLOR CLASSES   *
 ***************************************/

/**
 * BRAND COLORS
 */

// ORANGE
.cc-color--orange-400 {
  color: $orange-400;
}
.cc-bg--orange-400 {
  background-color: $orange-400;
}
.cc-border--orange-400 {
  border-color: $orange-400;
}

.cc-color--orange-500,
.cc-color--primary {
  color: $orange-500;
}
.cc-bg--primary,
.cc-bg--orange-500 {
  background-color: $orange-500;
}
.cc-border--primary,
.cc-border--orange-500 {
  border-color: $orange-500;
}

.cc-color--orange-600 {
  color: $orange-600;
}
.cc-bg--orange-600 {
  background-color: $orange-600;
}
.cc-border--orange-600 {
  border-color: $orange-600;
}

.cc-bg--orange-FFF0E5,
.cc-bg--orange-secondary {
  background-color: $orange-secondary;
}

// TEAL
.cc-color--teal-400 {
  color: $teal-400;
}
.cc-bg--teal-400 {
  background-color: $teal-400;
}
.cc-border--teal-400 {
  border-color: $teal-400;
}

.cc-color--secondary,
.cc-color--teal-500 {
  color: $teal-500;
}
.cc-bg--teal-500,
.cc-bg--secondary {
  background-color: $teal-500;
}
.cc-border--teal-500 {
  border-color: $teal-500;
}

.cc-color--teal-600 {
  color: $teal-600;
}
.cc-bg--teal-600 {
  background-color: $teal-600;
}
.cc-border--teal-600 {
  border-color: $teal-600;
}


// BROWN
.cc-color--brown-400 {
  color: $brown-400;
}
.cc-bg--brown-400 {
  background-color: $brown-400;
}
.cc-border--brown-400 {
  border-color: $teal-400;
}

.cc-color--brown-500 {
  color: $brown-500;
}
.cc-bg--brown-500 {
  background-color: $brown-500;
}
.cc-border--brown-500 {
  border-color: $brown-500;
}

.cc-color--brown-600 {
  color: $brown-600;
}
.cc-bg--brown-600 {
  background-color: $brown-600;
}
.cc-border--brown-600 {
  border-color: $brown-600;
}


/**
 *  THEME COLORS
 */

// GREEN
.cc-color--green-400 {
  color: $green-400;
}
.cc-bg--green-400 {
  background-color: $green-400;
}
.cc-border--green-400 {
  border-color: $green-400;
}

.cc-color--green-450 {
  color: $green-450;
}
.cc-bg--green-450 {
  background-color: $green-450;
}
.cc-border--green-450 {
  border-color: $green-450;
}

.cc-color--green-500 {
  color: $green-500;
}
.cc-bg--green-500 {
  background-color: $green-500;
}
.cc-border--green-500 {
  border-color: $green-500;
}

.cc-color--green-600 {
  color: $green-600;
}
.cc-bg--green-600 {
  background-color: $green-600;
}
.cc-border--green-600 {
  border-color: $green-600;
}

// BLUE-GRAY
.cc-color--blue-grey-300,
.cc-color--blue-gray-300 {
  color: $blue-gray-300;
}
.cc-bg--blue-grey-300,
.cc-bg--blue-gray-300 {
  background-color: $blue-gray-300;
}
.cc-border--green-400 {
  border-color: $green-400;
}


// TEXT
.cc-color--text-400 {
  color: $text-400;
}
.cc-bg--text-400 {
  background-color: $text-400;
}
.cc-border--green-400 {
  border-color: $green-400;
}

.cc-color--text-500 {
  color: $text-500;
}
.cc-bg--text-500 {
  background-color: $text-500;
}
.cc-border--text-400 {
  border-color: $text-400;
}


/**
 *  SYSTEM COLORS
 */

// POSITIVE
.cc-color--positive {
  color: $positive;
}
.cc-bg--positive {
  background-color: $positive;
}
.cc-border--positive {
  border-color: $positive;
}

// WARNING/YELLOW
.cc-color--warning {
  color: $yellow-500;
}
.cc-bg--warning {
  background-color: $yellow-500;
}
.cc-border--warning {
  border-color: $warning;
}

.cc-color--yellow-400 {
  color: $yellow-400;
}
.cc-bg--yellow-400 {
  background-color: $yellow-400;
}

.cc-color--yellow-600 {
  color: $yellow-500;
}
.cc-bg--yellow-600 {
  background-color: $yellow-500;
}


// NEGATIVE/RED
.cc-color--negative {
  color: $red-500;
}
.cc-bg--negative {
  background-color: $red-500;
}
.cc-border--negative {
  border-color: $negative;
}

.cc-color--red-300 {
  color: $red-300;
}
.cc-bg--red-300 {
  background-color: $red-300;
}

.cc-color--red-400 {
  color: $red-400;
}
.cc-bg--red-400 {
  background-color: $red-400;
}

.cc-color--red-600 {
  color: $red-600;
}
.cc-bg--red-600 {
  background-color: $red-600;
}

.cc-color--bright-red {
  color: $brightRed;
}
.cc-bg--bright-red {
  background-color: $brightRed;
}


// ACCENT
.cc-color--accent {
  color: $accent;
}
.cc-bg--accent {
  background-color: $accent;
}





/**
 * WHITES, GRAYS, BLACKS
 */

// WHITE
.cc-color--white-100 {
  color: $white-100;
}
.cc-bg--white-100 {
  background-color: $white-100;
}

.cc-color--white-200 {
  color: $white-200;
}
.cc-bg--white-200 {
  background-color: $white-200;
}


// GRAY
.cc-color--gray-600 {
  color: $gray-600;
}
.cc-bg--gray {
  background-color: $gray-600;
}

.cc-color--gray-100 {
  color: $gray-100;
}
.cc-bg--gray-100 {
  background-color: $gray-100;
}

.cc-color--gray-200 {
  color: $gray-200;
}
.cc-bg--gray-200 {
  background-color: $gray-200;
}

.cc-color--gray-300 {
  color: $gray-300;
}
.cc-bg--gray-300 {
  background-color: $gray-300;
}

.cc-color--gray-350 {
  color: $gray-350;
}
.cc-bg--gray-350 {
  background-color: $gray-350;
}

.cc-color--gray-400 {
  color: $gray-400;
}
.cc-bg--gray-400 {
  background-color: $gray-400;
}

.cc-color--gray-450 {
  color: $gray-450;
}
.cc-bg--gray-450 {
  background-color: $gray-450;
}

.cc-color--gray-500 {
  color: $gray-500;
}
.cc-bg--gray-500 {
  background-color: $gray-500;
}






// BLACK
.cc-color--black-500 {
  color: $black-500;
}
.cc-bg--black-500 {
  background-color: $black-500;
}

.cc-color--black-900 {
  color: $black-900;
}
.cc-bg--black-900 {
  background-color: $black-900;
}