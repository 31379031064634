.app--header {
  font-weight: 400;
}

.app--header-title {
  padding: 25px 15px 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 1.8rem;
  }
}

.app--header-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: inline-block;
}

.app--header-desc {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 15px 15px;
  font-size: 1.25rem;
}

.color-capcenter-navy{
  color: #0C2C48;
}

@media (max-width: 991px) {
  .app--header-desc {
    display: flex
  }
}