$s: 1rem;

// $s-0 : $s*0; 	   //0rem
$s-1 : $s*0.25; 	//0.25rem 4p
$s-2 : $s*0.5;    //0.5rem 8p
$s-3 : $s*0.75;	  //0.75rem 12p
$s-4 : $s;     	  //1rem 16p
$s-5 : $s*1.25;	  //1.25rem 20p
$s-6 : $s*1.5;	  //1.5rem 24p
$s-7 : $s*1.75;	  //1.75rem 28p
$s-8 : $s*2; 	    //2rem 32p
$s-9 : $s*2.25;   //2.25rem 36p
$s-10 : $s*2.5;   //2.5rem 40p
$s-12 : $s*3;  	  //3rem 48p
$s-14 : $s*3.5;   //3.5rem 56p
$s-15 : $s*3.75;  //3.75rem 60p
$s-16 : $s*4; 	  //4rem 64p
$s-18 : $s*4.5;	  //4.5rem 72p
$s-20 : $s*5;  	  //5rem 80p
$s-23 : $s*5.75;	//5.75rem 92p
$s-24 : $s*6; 	  //6rem 96p
$s-28 : $s*7; 	  //7rem 112p
$s-31 : $s*7.75; 	//7.75rem 124p
$s-32 : $s*8; 	  //8rem 128p
$s-40 : $s*10; 	  //10rem 160p
$s-44 : $s*11; 	  //11rem 176p
$s-46 : $s*11.5; 	//11.5rem 184p
$s-64 : $s*16; 	  //16rem 256p
$s-80 : $s*17;    //17rem 272p
$s-92 : $s*20;    //20rem 320p


// :export {
//   s-2: $s-2;
//   s-3: $s-3;
//   s-5: $s-5;
//   s-6: $s-6;
// }