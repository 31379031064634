$small: 576px;
$medium: 768px;
$large: 992px;
$xLarge: 1200px;
$xxLarge: 1600px;

// Export variables for use in React.
:export {
  small: $small;
  medium: $medium;
  large: $large;
  xLarge: $xLarge;
  xxLarge: $xxLarge;
}
