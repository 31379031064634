@import '~@capcenter/shared/src/constants/_colors.scss';
@import '~@capcenter/shared/src/styling/Breakpoints';

.btngroup {
  display: inline-block;
  font-size: inherit;
  font-family: omnes-pro, Arial, sans-serif;
  cursor: pointer;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-style: solid;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 0.375rem 0.375rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  background-color: $white-100;
  border-color: #647778;
  z-index: 1;
  color: $black-500;
  white-space: nowrap;
  margin-left: -1px;
  margin-top: 0;
  max-width: none;
}

// Right-most button (vertical bottom)
.rightEdge {
  border-top-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
  border-right-width: 1px;
  margin-right: 0px;

  .clipBorder {
    border-right-width: 0;
  }
}

// Left-most button (vertical top)
.leftEdge {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-left-width: 1px;
  margin-left: 0;

  .clipBorder {
    border-left-width: 0;
  }
}

.vertical {
  width: auto;
  margin-left: 0;
  margin-top: -1px;
  max-width: none;
}

// RIGHT_EDGE
.vertical.rightEdge {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

// LEFT_EDGE
.vertical.leftEdge {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
  margin-top: 0;
  margin-left: 0;
  margin-top: 0;
}

.smallText {
  font-size: 0.85rem;
}

.bigText {
  white-space: normal;
}

.invalid {
  border-color: $brightRed;
}

.disabled {
  color: $gray-450;
  background-color: $gray-350;
  border-color: $gray-400;
  z-index: 0;
}

.invalid {
  border-color: $brightRed;
}

.disabled {
  color: $gray-450;
  background-color: $gray-350;
  border-color: $gray-400;
  z-index: 0;
}

.active {
  background-color: #FFF0E5;
  z-index: 2;
  border-color: #647778;
}

@media screen and (min-width: $small) {
  button:hover {
    z-index: 5;
    outline: 2px solid #647778 !important;
  }
}
