$light: 400;
$normal: 500;
$bold: 600;
$bolder: 700;

:export {
	light: $light;
	normal: $normal;
  bold: $bold;
  bolder: $bolder;
}
