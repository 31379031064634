/**
 *  HOW TO USE:
 *  Simply import this Animations.scss file into another .scss file
 *  and use @extend: .[classname] to extend any pre-made class to another *  class (see Button.scss for example)
 *  
 * @extends docs: https://sass-lang.com/documentation/at-rules/extend
 */

@import "../constants/variables";
@import "../constants/_colors.scss";

/**
 * BUTTON RIPPLE ANIMATION
 */
.ripple {
	position: relative;
	overflow: hidden;

	&:after {
		content: "";
		background: rgba($white-100, 0.2);
		display: block;
		position: absolute;
		border-radius: 50%;
		padding-top: 240%;
		padding-left: 240%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		transition: all 0.5s;
	}

	&:active:after {
		padding-top: 0;
		padding-left: 0;
		margin-top: 0;
		margin-left: 0;
		opacity: 1;
		transition: 0s;
	}

}

.ripple--dark:after {
	background: rgba($gray-450, 0.3);
}





.pulsate {
	animation: pulsate 1.25s ease-out;
	animation-iteration-count: infinite;
	-webkit-animation: pulsate 1.25s ease-out;
	-webkit-animation-iteration-count: infinite;
	opacity: 0.25;
}

@keyframes pulsate {
	0% { 
		opacity: 0.5;
	}
	50% { 
		opacity: 1.0;
	}
	100% { 
		opacity: 0.5;
	}
}

/**
 * Shake animation
 * Used for form validation error messages
 */
.shake {
	animation: shake-animation .35s ease;
	transform-origin: 50% 50%;
}

@keyframes shake-animation {
	0% {
		transform:translate(5px,0);
	}
	12.5% {
		transform:translate(5px,0);
	}
	25% {
		transform:translate(0,0);
	}
	37.5% {
		transform:translate(5px,0);
	}
	50% {
		transform:translate(0,0);
	}
	62.5% {
		transform:translate(5px,0);
	}
	75% {
		transform:translate(0,0);
	}
	100% {
		transform:translate(0,0);
	}
}





/**
 * TRANSITION ANIMATIONS
 */
.anim__ease--quick {
	-webkit-transition: all $anim-ease-quick;
	-moz-transition: all $anim-ease-quick;
	transition: all $anim-ease-quick;
}
.anim__cubic--quick {
	-webkit-transition: all $anim-cubic-quick;
	-moz-transition: all $anim-cubic-quick;
	transition: all $anim-cubic-quick;
}
.anim__cubic--quicker {
	-webkit-transition: all $anim-cubic-quicker;
	-moz-transition: all $anim-cubic-quicker;
	transition: all $anim-cubic-quicker;
}