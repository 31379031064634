@media (min-width: 768px) {
  .cc-nav-2023 {
    .cc-nav-container {
      .cc-nav-row {
        ul.cc-nav-list {
          li.cc-nav-brand {
            svg {
              margin-right: 3px;
              margin-top: 3px;
            }
          }
        }
        .cc-nav-right {
          height: 100%;
          ul.cc-nav-list-right {
            column-gap: 1rem;
            height: 100%;
            list-style: none;
            margin: 0;
            padding: 0;

            .menu-item-accounts {
              flex-shrink: 0;
              display: flex;
              align-items: center;
              height: 100%;
              margin-top: auto;
              margin-bottom: auto;

              button {
                color: rgb(255 255 255);
                //color: #ea701b;
                text-align: center;
                padding: 10px 2rem;
                //background-color: rgb(255 255 255);
                background-color: #ea701b;
                border-width: 1px;
                justify-content: center;
                align-items: center;
                height: 34px;
                display: flex;
                position: relative;
                overflow: hidden;
                position: relative;
                transition-timing-function: cubic-bezier(.4,0,1,1);
                transition-duration: .2s;
                font-size: 1rem;
                line-height: 1.5rem;
                //border-color: rgb(255 255 255);
                border-color: #ea701b;
                border-radius: 9999px;
                border-style: solid;

                &:hover {
                  background-color: #B44100;
                }
              }
            }
          }
        }
      }
    }
    .cc-nav-mobile-dropdown-menu {
      .cc-nav-mobile-dropdown-menu-container {
        .cc-nav-mobile-dropdown-menu-accordion {
          .cc-nav-mobile-dropdown-menu-accounts-div {
            .cc-nav-mobile-dropdown-menu-accounts-button-solid-background {
              display:none;
            }
          }
        }
      }
    }
    
  }
}
