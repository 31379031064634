.SVGFBackground, .SVGFPiggyFore, .SVGFCoin, .SVGFChat, .SVGFCheck, .SVGFCheckInsert, .SVGFCheckShadow, .SVGFBubbles, .SVGFCalcManFootShadow{
	width: 100%;
	height: 100%;	
}

.SVGFBubbles {
	animation: bounce1 18s linear infinite;
}

.SVGFCoin {
  animation: coin 8s ease-in-out infinite;
}

@keyframes coin {
  0% {
    transform: translate(0%, 0%);
  }
  40% {
    transform: translate(0%, 23%);
  }
  80% {
	  transform: translate(0%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

.SVGFCoinManHead {
	width: 2.75%;
	top: 13%;
	left: 43.75%;
  animation: looking 8s ease infinite;
}

@keyframes looking {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.SVGFCoinManArmFore {
	width: 4%;
	top: 16.5%;
	left: 41.75%;
  animation: droppingfore 8s ease infinite;
  transform-origin: top left;
}

@keyframes droppingfore {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(25deg);
  }
  65% {
    transform: rotate(25deg);
  }
  80% {
	  transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.SVGFCoinManArmBack {
	width: 8.5%;
	top: 16%;
	left: 45.75%;
  animation: droppingback 8s ease infinite;
  transform-origin: top left;
}

@keyframes droppingback {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(-25deg);
  }
  65% {
    transform: rotate(-25deg);
  }
  80% {
	  transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.SVGFChat{
  animation: chat 5s linear infinite;
}

@keyframes chat {
  0% {
    transform: translate(0%, 0%);
  }
  20% {
    transform: translate(0%, -2%);
  }
  80% {
	  transform: translate(0%, 2%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

.SVGFCheck{
  animation: clickcheck 4s ease-out infinite;	
}

@keyframes clickcheck {
  0% {
    transform: translate(0%, 0%);
  }
  40% {
    transform: translate(0%, 1.3%);
  }
  60% {
    transform: translate(0%, 1.3%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

.SVGFCheckShadow {
  animation: clickcheckshadow 4s ease-out infinite;		
}

@keyframes clickcheckshadow {
  0% {
    transform: translate(0%, 0%);
  }
  40% {
    transform: translate(-1.3%, -.3%);
  }
  60% {
    transform: translate(-1.3%, -.3%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}

.SVGFCalcManFootShadow {
  animation: calcmanshadow 6s linear infinite;	
  transform-origin: bottom center;	
}

@keyframes calcmanshadow {
  0% {
    transform: scale(1) translate(0%, 0%);
  }
  20% {
    transform: scale(1.25) translate(1.75%, 2.4%);
  }
  40% {
    transform: scale(1.5) translate(3%, 4.8%);
  }
  50% {
    transform: scale(1.5) translate(3%, 4.8%);
  }
  60% {
    transform: scale(1.25) translate(1.75%, 2.4%);
  }
  80% {
    transform: scale(1) translate(0%, 0%);
  }
  100% {
    transform: scale(1) translate(0%, 0%);
  }
}

.SVGFCalcMan {
	width: 9.8%;
	bottom: 13.25%;
	left: 41.4%;	
  animation: calcman 6s linear infinite;	
  transform-origin: bottom right;	
}

@keyframes calcman {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(-4deg);
  }
  80% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.SVGFCalcManLeg {
	width: 3.5%;
	bottom: 15.75%;
	left: 39.25%;	
  animation: calcmanleg 6s linear infinite;	
  transform-origin: top right;	
}

@keyframes calcmanleg {
  0% {
    transform: rotate(0deg) translate(0%, 0%);
  }
  40% {
    transform: rotate(-20deg) translate(-20%, 0%);
  }
  50% {
    transform: rotate(-20deg) translate(-20%, 0%);
  }
  80% {
    transform: rotate(0deg) translate(0%, 0%);
  }
  100% {
    transform: rotate(0deg) translate(0%, 0%);
  }
}

.SVGFCalcManFoot {
	width: 2.6%;
	bottom: 13.25%;
	left: 38.25%;	
  animation: calcmanfoot 6s linear infinite;	
  transform-origin: top right;	
}

@keyframes calcmanfoot {
  0% {
    transform: rotate(0deg) translate(0%, 0%);
  }
  40% {
    transform: rotate(-70deg) translate(-20%, 0%);
  }
  50% {
    transform: rotate(-70deg) translate(-20%, 0%);
  }
  80% {
    transform: rotate(0deg) translate(0%, 0%);
  }
  100% {
    transform: rotate(0deg) translate(0%, 0%);
  }
}

.SVGFCalcManHead {
	width: 2.7%;
	bottom: 33.2%;
	left: 43.4%;
  transform: rotate(0deg) translate(0%, 0%);
  animation: calcmanhead3 6s linear infinite;	
}

@keyframes calcmanhead3 {
  0% {
    transform: rotate(0deg) translate(0%, 0%);
  }
  40% {
    transform: rotate(25deg) translate(-30%, 30%);
  }
  50% {
    transform: rotate(25deg) translate(-30%, 30%);
  }
  80% {
    transform: rotate(0deg) translate(0%, 0%);
  }
  100% {
    transform: rotate(0deg) translate(0%, 0%);
  }
}

.SVGFWomanArm {
	width: 2%;
	bottom: 40.2%;
	left: 8.6%;	
  transform-origin: top right;
  animation: womanarm 6s ease infinite;		
}

@keyframes womanarm {
  0% {
    transform: rotate(0deg)
  }
  10% {
    transform: rotate(0deg)
  }
  40% {
    transform: rotate(-150deg)
  }
  45% {
    transform: rotate(-140deg)
  }
  50% {
    transform: rotate(-150deg)
  }
  55% {
    transform: rotate(-140deg)
  }
  60% {
    transform: rotate(-150deg)
  }
  90% {
    transform: rotate(0deg)
  }
  100%{
	  transform: rotate(0deg)
  }
}