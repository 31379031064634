.infoButton {
    &.show {
        opacity: 1
    }

    .tooltip-inner {
        text-align: left;
        background-color: #495F61;
        color: #FFFFFF;
        border: 1px solid #495F61;
    }
}