@import '../../constants/weights';
@import '../../constants/colors';
@import '../../constants/variables';
@import '../../styling/Animations.scss';
@import '../../styling/Shadows/Shadows.scss';
@import '../../constants/Borders.scss';

a.blog-preview-link__wrapper {
  @extend .anim__cubic--quick;
  color: $text-500;

  &:hover {
    color: $primary;
    text-decoration: none;
  }
}

.blog-preview-link {
  @extend .border-radius-lg;
  @extend .cc-shadow--light;
  overflow: hidden;
}

.blog-preview-link__img {
  height: 8.5rem;
  width: 100%;
  background-size: cover;
  background-position: center 10%;
  background-repeat: no-repeat;
}

.blog-preview-link__body {
  background-color: $white-100;
  height: 10rem;
}

.blog-preview-link__title {
  font-weight: $bold;
  font-size: $font-md;
  padding-top: .25rem;
}

@media (min-width: 768px) {
  .blog-preview-link__body {
    height: 9rem;
  }
}

@media (min-width: 992px) {
  .blog-preview-link__body {
    height: 11rem;
  }
}

@media (min-width: 1200px) {
  .blog-preview-link__body {
    height: 10rem;
  }
}