/*------------------------------------*\
    #TABLE OF CONTENTS
\*------------------------------------*/

/**
 * #COLOR VARS ............... Define 'theme' & 'system' colors
 * #SPACING .................. Define spacing variables based on .25rem units
 * #SHADOWS .................. Define 'light' & 'inner' shadows
 * #BORDERS .................. Define border widths & radii
 * #BREAKPOINTS .............. Define breakpoints based on Bootstrap's dimensions
 * #ANIMATIONS ............... Define animation duration, type, & their combinations
 * #TYPOGRAPHY ............... Define fonts and font & icon sizes and weights
 */





/*-----------------*\
    #BREAKPOINTS
\*-----------------*/

/**
 * Breakpoints
 */
$bp-xs : 360px;
$bp-sm : 576px;
$bp-md : 768px;
$bp-lg : 992px;
$bp-xl : 1200px;





/*----------------------*\
    #ANIMATION VARS
\*----------------------*/
/**
 * DURATION
 */
$anim-slow : 1s;
$anim-med : 0.6s;
$anim-quick : 0.3s;
$anim-quicker : 0.2s;


/**
 * TYPES
 */
$anim-ease : ease;
$anim-ease-io : ease-in-out;
$anim-cubic : cubic-bezier(.17,.67,.83,.67);
$anim-cubic : cubic-bezier(0.215, 0.61, 0.355, 1);


/**
 * COMBO
 */
$anim-cubic-slow : $anim-cubic $anim-slow;
$anim-cubic-med : $anim-cubic $anim-med;
$anim-cubic-quick : $anim-cubic $anim-quick;
$anim-cubic-quicker : $anim-cubic $anim-quicker;

$anim-ease-slow : $anim-ease $anim-slow ;       // ease 1s
$anim-ease-med : $anim-ease $anim-med;          // ease 0.6s
$anim-ease-quick : $anim-ease $anim-quick;      // ease 0.3s
$anim-ease-quicker : $anim-ease $anim-quicker;  // ease 0.2s

$anim-ease-io-slow : $anim-ease-io $anim-slow;        // ease-in-out 1s
$anim-ease-io-med : $anim-ease-io $anim-med;          // ease-in-out 0.6s
$anim-ease-io-quick : $anim-ease-io $anim-quick;      // ease-in-out 0.3s
$anim-ease-io-quicker : $anim-ease-io $anim-quicker;  // ease-in-out 0.2s





/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/

/**
 * FONTS
 */
$font-primary : 'Omnes', 'Helvetica', 'Arial', sans-serif;
$font-secondary : 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;


/**
 * FONT SIZES
 */
$font-xs : 0.75rem;
$font-sm : 0.875rem;
$font-base : 1rem;
$font-md : 1.1625rem;
$font-lg : 1.4375rem;
$font-xl : 1.8125rem;
$font-xxl : 2.15rem;
$font-xxxl : 2.4875rem;



/**
 * LEADING/LINE-HEIGHT
 */
$leading-none : 1;
$leading-tight : 1.25;
$leading-snug : 1.375;
$leading-normal : 1.5;
$leading-relaxed : 1.625;
$leading-loose : 2;


/**
 * ICON SIZES
 */
// $icon-med : $s-4;