@import '../../../../node_modules/@capcenter/shared/src/styling/Breakpoints';

.sell-hero {
  background-color: #FFF5ED;
  height: 600px;

  .sell-hero-left {
    height: 360px;
  }

  .sell-hero-right {
    height: 240px;
  }

  .sell-hero-main {
    text-align: center;
    padding: 30px 30px;

    h1 {
      font-family: 'proxima-nova';
      color: #0C2C48;
      font-size: 32px;
      line-height: 35px;
      margin-bottom: 15px;
      font-weight: bold;
    }

    h2 {
      font-family: 'acumin-pro';
      color: #435759;
      font-size: 1.3em;
      margin-bottom: 20px;
    }

    button {
      border-radius: 2px;
      background-color: transparent;
      border: #EA701B solid 2px;
      color: #EA701B;
      font-family: 'acumin-pro';
      font-size: 16px;
      padding: 5px 10px;
      width: 100%;
      margin-bottom: 20px;

      &:hover {
        background-color: #EA701B;
        color: white;
      }

      &.btn-primary {
        color: white;
        background-color: #EA701B;

        &:hover {
          background-color: #B44100;
        }
      }
    }
  }

  .sell-hero-image {
    background-image: url('./hero-image-xs.jpg');
    background-size: cover;
    background-position: 0px -70px;
    background-repeat: no-repeat;
    height: 360px;
  }

  .text-overlap {
    position: relative;
    top: -720px;
  }
}

@media (min-width: $small) {
  .sell-hero {
    .sell-hero-main {
      padding: 50px 20px;

      h1 {
        margin-top: 0px;
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 20px;
      }

      h2 {
        margin-bottom: 30px;
      }

      button {
        width: 100%;
      }
    }
  }
}

@media (min-width: $medium) {
  .sell-hero {
    height: 600px;

    .sell-hero-left {
      height: 360px;
    }

    .sell-hero-right {
      height: 240px;
    }

    .sell-hero-main {
      text-align: center;

      h1 {
        margin-bottom: 30px;
      }

      h2 {
        font-size: 20px;
      }

      button {
      }
    }

    .sell-hero-image {
      background-image: url('./hero-image-md.jpg');
      background-position: 0px -10px;
    }

    .text-overlap {
      position: relative;
      top: -600px;
    }
  }
}

@media (min-width: $large) {
  .sell-hero {
    height: 380px;

    .sell-hero-left {
      height: 380px;
    }

    .sell-hero-right {
      height: 380px;
    }

    .sell-hero-main {
      text-align: left;
      padding-right: 70px;

      h1 {
        line-height: 45px;
        margin-bottom: 20px;
      }

      h2 {
        margin-bottom: 20px;
      }

      .col-sm {
        padding: 5px;
      }

      button {
        padding: 5px 0;
        width: 100%;
      }
    }

    .sell-hero-image {
      background-image: url('./hero-image-lg.jpg');
      background-position: 0px -115px;
    }

    .text-overlap {
      position: relative;
      top: -380px;
    }
  }
}

@media (min-width: $xLarge) {
  .sell-hero {
    height: 380px;

    .sell-hero-left {
      height: 380px;
    }

    .sell-hero-right {
      height: 380px;
    }

    .sell-hero-main {
      padding-right: 120px;

      h2 {
        margin-bottom: 40px;
      }
    }

    .sell-hero-image {
      background-image: url('./hero-image-xl.jpg');
      background-size: cover;
      background-position: 0px -50px;
      height: 380px;
    }

    .text-overlap {
      position: relative;
      top: -380px;
    }
  }
}