.cc--content-wrap-with-footer {
  padding-bottom: 25px;
}

.cc-text-nav.light {
  background-color: white;
  color: gray;
}
.footer.light {
  background-color: white;
  color: gray;

  a {
    color: gray;

    &:hover {
      color: #c5570a;
    }
  }

  .footer--cred {
    color: gray;
  }

  .footer--legal {
    color: gray;
  }
}


.footer {
  font-size: 80%;
  padding: 15px 0 40px;
  color: #FFFFFF;
  background-color: #403f3e;
  font-weight: 500;
  overflow: hidden;

  a {
    color: #FFFFFF;
  }
}

.cc-text-nav {
  margin-top:-25px;
  background: #EBF1F2;
  box-shadow: inset 0px -5px 15px -12px #647778;
  font-size: 110%;
  padding: 90px 0 50px 0;

  .container {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: 0px;
      margin-left: 0px;

      .brand {
        svg {
          margin-right: 5px;
        }
      }

      h3 {
        font-weight: bold;
        font-size: 40px;
        line-height: 40px;
        font-family: proxima-nova;
      }

      .subtitle-sm {
        margin-top: -10px;
        margin-bottom: 10px;
        font-size: 12px;
        position: relative;
        left: 350px;
      }

      .subtitle-xs {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 2px;
        font-size: 12px;
      }

      h4 {
        font-weight: bold;
        size: 1.8em;
        font-family: proxima-nova;

        a {
          font-weight: bold;
          size: 1.8em;
          font-family: proxima-nova;
          cursor: pointer;
        }
      }

      .col-12 {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.cc--text-nav {
  font-size: 110%;
  line-height: 1.2;

  a {
    color: #0C2C48;
    font-family: omnes-pro, acumin-pro, sans-serif;
    font-size: 16px;
    line-height: 25px;

    &:hover {
      color: #F47C28;
      text-decoration: underline;
    }
  }

  .contact-link {
    .fas {
      margin-right:5px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.footer--links {
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 120%;
  line-height: 1.9;
}

.footer--contact {
  text-align: right;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 120%;
  div {
    margin-bottom: 15px;
  }
}

.footer--icon {
  padding: 1rem;
}

.footer--social {
  text-align: center;
  font-size: 300%;
  margin-bottom: 15px;

  a:hover {
    color: #F47C28;
  }
}

.footer--cred {
  text-align: center;
  font-size: 600%;
  margin-bottom: 15px;
  color: lighten(#7D706D, 50%);
}

.footer--legal {
  text-align: center;
  line-height: 1;
  color: lighten(#7D706D, 50%);
}

.mw--200 {
  min-width: 200px;
}


@media (min-width: 576px) {
  .cc-text-nav {

    .container {
      max-width: 540px;

      .row {
        .col-12 {
          margin-bottom: 1rem;

          h4 {
            a {
              font-size: 19px;
            }
          }

          a {
            font-size: 16px;
            line-height: 25px;
          }
        }

        .col-sm-6 {
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .cc-text-nav {
    .container {
      max-width: 720px;
    }
  }

  .footer {
    font-size: 100%;
  }
}

@media (min-width: 992px) {
  .cc-text-nav {
    .container {
      max-width: 960px;
    }
  }
}

@media (min-width: 1200px) {
  .cc-text-nav {
    .container {
      max-width: 1140px;
    }
  }
}