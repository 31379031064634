.service-area-static-img--wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.service-area-static-img--img-block {
  flex-grow: 5;
  position: relative;
  overflow: hidden;
  height: 22rem;
  width: 100%;
}

.service-area-static-img--img-block img {
  position: relative;
  height: 22rem;
  width: 110rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: .5s;
  display: flex;
}

.service-area-static-img--legend-block {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: .25rem;
  color: #212529;
}

@media (max-width:700px) {
  .service-area-static-img--img-block img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 75%;
}
}

@media (min-width:768px) and (max-width:850px) {
  .service-area-static-img--img-block img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 51%;
}
}