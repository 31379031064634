// Note: before adding anything to this file, make sure it doesn't belong in the tablets file which also covers phones
@media only screen and (max-width: 767px) {
  .landing-page {
    max-width: 720px;
    font-size: larger;
  }
  .breadcrumb {
    max-width: 720px;
    font-size: larger;
  }
}

@media only screen and (max-width: 992px) {
  .landing-page {
    max-width: 960px;
    font-size: large;
  }
  .breadcrumb {
    max-width: 960px;
    font-size: large;
  }
}
  