@import './spacing.scss';

/*-----------------------*\
    #BORDERS
\*-----------------------*/
/**
 *  Border radius
 */
$border-radius-0 : 0;
$border-radius-sm : 0.125rem;
$border-radius : $s-1;
$border-radius-lg : $s-2;
$border-radius-xl : $s-3;
$border-radius-xxl : $s-4;
$border-radius-full : 9999px;
/**
 *  Border width
 */
// .border-0 {
//   border-width: 0;
// }
// .border {
//   border-width: 1px;
// }
// .border-2 {
//   border-width: 2px;
// }
// .border-4 {
//   border-width: 4px;
// }
 
 
 
 

/**
 *  Border radius
 */
.border-radius-0 {
  border-radius: $border-radius-0;
}
.border-radius-sm{
  border-radius: $border-radius-sm;
} 
.border-radius{
  border-radius: $border-radius;
} 
.border-radius-lg{
  border-radius: $border-radius-lg;
} 
.border-radius-xl{
  border-radius: $border-radius-xl;
} 
.border-radius-xxl{
  border-radius: $border-radius-xxl;
} 
.border-radius-full{
  border-radius: $border-radius-full;
} 