@import '../../constants/colors';
@import '../../constants/weights';

.tool-template.quote-details {
  .btn-geolocate {

  }
}

@media (max-width: 765px) {
  .calculator-tab-group-wrapper {
    margin: -24px -13px 10px -15px;
  }

  .calculator-property-type-upper-content {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
@media (min-width: 766px) {
  .calculator-tab-group-wrapper {
    margin: -24px -24px 10px -24px;
  }
  
  .calculator-property-type-upper-content {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}

.calculator-tab-group {
  margin-bottom: 10px;
  display: flex;
  vertical-align: middle;
  position: relative;
  flex-direction: row;
}

.calculator-tab {
  display: inline-block;
  font-size: 1rem;
  font-family: omnes-pro, Arial, sans-serif;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  transition: color 0.15s ease-in-out 0s, backgroundColor 0.15s ease-in-out 0s, borderColor 0.15s ease-in-out 0s, boxShadow 0.15s ease-in-out 0s;
  z-index: 2;
  white-space: nowrap;
  margin-left: 0;
  margin-top: 0;
  max-width: none;
  text-decoration: none;
  background-color: #849697;
  border-color: #849697;
  color: rgb(255, 255, 255);
  text-decoration: none;
  text-align: center;
  padding: 0.670rem;

  &.left {
    border-radius: 0.25rem 0px 0px 0px;
  }

  &.right {
    border-radius: 0px 0.25rem 0px 0px;
  }

  &.active {
    background-color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 0.375rem;

    &:hover {
      color: rgb(0,0,0);
      background-color: rgb(255,255,255);
    }

    .loanType {
      font-size: 1.3rem;
      font-family: omnes-pro, Arial, sans-serif;
      font-weight: normal;
      margin-top: 0px;
      margin-bottom: 0;
    }
  }

  &:hover {
    color: rgb(255,255,255);
    text-decoration: none;
    background-color: rgb(12, 44, 72);
  }

  h1 {
    font-size: 1rem;
    font-family: omnes-pro, Arial, sans-serif;
    font-weight: normal;
  }
}

.advanced-controls-icon__property--inactive {
  color: rgb(0, 154, 168);
}
.advanced-controls-icon__property--active {
  color: rgb(255, 255, 255);
}

.advanced-controls-btn__property--inactive {
  background-color: transparent !important;
}
.advanced-controls-btn__property--active {
  background-color: rgb(0, 154, 168) !important;
}

.calculator-property-type-grid {
  padding-top: 0.25rem;
  display: grid;
  grid-template-rows: 1.5fr 1fr;   
  justify-content: center;    
  grid-template-columns: repeat(auto-fit, minmax(max-content, 1fr));
  display: grid;  
  grid-template-areas:
    "controls controls controls"
    "cta cta cta";
}

.calculator-property-type-upper-content {
  background-color: #EBF1F2;
  position: inherit;
  left: 0;
  right: 0;   
  grid-area: controls;   
  margin-top: 1.7rem;
  padding: 1.5rem;
}

.calculator-property-type-controls {
  padding: auto 1.5rem;    
  margin-top: -3.25em;
}

.calculator-property-type-lower-content {     
  grid-area: cta;
  margin-top: 0.5rem;
}

@media (min-width: 1201px) {
  .calculator-property-type-grid {
    grid-row-gap: 0;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .calculator-property-type-lower-content {     
    margin-top: 0.5rem;
  }
}

